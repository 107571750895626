/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { LanguageCreate } from '../models';
// @ts-ignore
import { LanguageList } from '../models';
// @ts-ignore
import { LanguageUpdate } from '../models';
/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Language
         * @param {LanguageCreate} languageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage: async (languageCreate: LanguageCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageCreate' is not null or undefined
            assertParamExists('createLanguage', 'languageCreate', languageCreate)
            const localVarPath = `/api/v1/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage: async (languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('deleteLanguage', 'languageId', languageId)
            const localVarPath = `/api/v1/languages/{language_id}`
                .replace(`{${"language_id"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage: async (languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('getLanguage', 'languageId', languageId)
            const localVarPath = `/api/v1/languages/{language_id}`
                .replace(`{${"language_id"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Language
         * @param {number} languageId 
         * @param {LanguageUpdate} languageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage: async (languageId: number, languageUpdate: LanguageUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('updateLanguage', 'languageId', languageId)
            // verify required parameter 'languageUpdate' is not null or undefined
            assertParamExists('updateLanguage', 'languageUpdate', languageUpdate)
            const localVarPath = `/api/v1/languages/{language_id}`
                .replace(`{${"language_id"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Language
         * @param {LanguageCreate} languageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLanguage(languageCreate: LanguageCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLanguage(languageCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLanguage(languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLanguage(languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguage(languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguage(languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Language
         * @param {number} languageId 
         * @param {LanguageUpdate} languageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLanguage(languageId: number, languageUpdate: LanguageUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLanguage(languageId, languageUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Language
         * @param {LanguageCreate} languageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage(languageCreate: LanguageCreate, options?: any): AxiosPromise<LanguageList> {
            return localVarFp.createLanguage(languageCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage(languageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteLanguage(languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Language
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage(languageId: number, options?: any): AxiosPromise<LanguageList> {
            return localVarFp.getLanguage(languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any): AxiosPromise<Array<LanguageList>> {
            return localVarFp.getLanguages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Language
         * @param {number} languageId 
         * @param {LanguageUpdate} languageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage(languageId: number, languageUpdate: LanguageUpdate, options?: any): AxiosPromise<LanguageList> {
            return localVarFp.updateLanguage(languageId, languageUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiCreateLanguageRequest
 */
export interface LanguageApiCreateLanguageRequest {
    /**
     * 
     * @type {LanguageCreate}
     * @memberof LanguageApiCreateLanguage
     */
    readonly languageCreate: LanguageCreate
}

/**
 * Request parameters for deleteLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiDeleteLanguageRequest
 */
export interface LanguageApiDeleteLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiDeleteLanguage
     */
    readonly languageId: number
}

/**
 * Request parameters for getLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiGetLanguageRequest
 */
export interface LanguageApiGetLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiGetLanguage
     */
    readonly languageId: number
}

/**
 * Request parameters for updateLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiUpdateLanguageRequest
 */
export interface LanguageApiUpdateLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiUpdateLanguage
     */
    readonly languageId: number

    /**
     * 
     * @type {LanguageUpdate}
     * @memberof LanguageApiUpdateLanguage
     */
    readonly languageUpdate: LanguageUpdate
}

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @summary Create Language
     * @param {LanguageApiCreateLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public createLanguage(requestParameters: LanguageApiCreateLanguageRequest, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).createLanguage(requestParameters.languageCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Language
     * @param {LanguageApiDeleteLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public deleteLanguage(requestParameters: LanguageApiDeleteLanguageRequest, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).deleteLanguage(requestParameters.languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Language
     * @param {LanguageApiGetLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getLanguage(requestParameters: LanguageApiGetLanguageRequest, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).getLanguage(requestParameters.languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getLanguages(options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).getLanguages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Language
     * @param {LanguageApiUpdateLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public updateLanguage(requestParameters: LanguageApiUpdateLanguageRequest, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).updateLanguage(requestParameters.languageId, requestParameters.languageUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
