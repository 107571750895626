/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { TestConsultationInfo } from '../models';
// @ts-ignore
import { TestCreate } from '../models';
// @ts-ignore
import { TestGet } from '../models';
// @ts-ignore
import { TestList } from '../models';
// @ts-ignore
import { TestQuestionnaires } from '../models';
// @ts-ignore
import { TestUpdate } from '../models';
/**
 * TestsApi - axios parameter creator
 * @export
 */
export const TestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Test
         * @param {TestCreate} testCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTest: async (testCreate: TestCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testCreate' is not null or undefined
            assertParamExists('createTest', 'testCreate', testCreate)
            const localVarPath = `/api/v1/tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTest: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('deleteTest', 'testId', testId)
            const localVarPath = `/api/v1/tests/{test_id}`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consultation Info By Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationInfoByTest: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getConsultationInfoByTest', 'testId', testId)
            const localVarPath = `/api/v1/tests/reports/{test_id}/consultation`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getTest', 'testId', testId)
            const localVarPath = `/api/v1/tests/{test_id}`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Test Questionnaires
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestQuestionnaires: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getTestQuestionnaires', 'testId', testId)
            const localVarPath = `/api/v1/tests/{test_id}/questionnaires`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTests: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Test
         * @param {number} testId 
         * @param {TestUpdate} testUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTest: async (testId: number, testUpdate: TestUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('updateTest', 'testId', testId)
            // verify required parameter 'testUpdate' is not null or undefined
            assertParamExists('updateTest', 'testUpdate', testUpdate)
            const localVarPath = `/api/v1/tests/{test_id}`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestsApi - functional programming interface
 * @export
 */
export const TestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Test
         * @param {TestCreate} testCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTest(testCreate: TestCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTest(testCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTest(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTest(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consultation Info By Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsultationInfoByTest(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestConsultationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationInfoByTest(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTest(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTest(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Test Questionnaires
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestQuestionnaires(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestQuestionnaires>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestQuestionnaires(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTests(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTests(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Test
         * @param {number} testId 
         * @param {TestUpdate} testUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTest(testId: number, testUpdate: TestUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTest(testId, testUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestsApi - factory interface
 * @export
 */
export const TestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Test
         * @param {TestCreate} testCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTest(testCreate: TestCreate, options?: any): AxiosPromise<TestList> {
            return localVarFp.createTest(testCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTest(testId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTest(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consultation Info By Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationInfoByTest(testId: number, options?: any): AxiosPromise<TestConsultationInfo> {
            return localVarFp.getConsultationInfoByTest(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Test
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest(testId: number, options?: any): AxiosPromise<TestGet> {
            return localVarFp.getTest(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Test Questionnaires
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestQuestionnaires(testId: number, options?: any): AxiosPromise<Array<TestQuestionnaires>> {
            return localVarFp.getTestQuestionnaires(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTests(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<TestList>> {
            return localVarFp.getTests(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Test
         * @param {number} testId 
         * @param {TestUpdate} testUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTest(testId: number, testUpdate: TestUpdate, options?: any): AxiosPromise<TestList> {
            return localVarFp.updateTest(testId, testUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTest operation in TestsApi.
 * @export
 * @interface TestsApiCreateTestRequest
 */
export interface TestsApiCreateTestRequest {
    /**
     * 
     * @type {TestCreate}
     * @memberof TestsApiCreateTest
     */
    readonly testCreate: TestCreate
}

/**
 * Request parameters for deleteTest operation in TestsApi.
 * @export
 * @interface TestsApiDeleteTestRequest
 */
export interface TestsApiDeleteTestRequest {
    /**
     * 
     * @type {number}
     * @memberof TestsApiDeleteTest
     */
    readonly testId: number
}

/**
 * Request parameters for getConsultationInfoByTest operation in TestsApi.
 * @export
 * @interface TestsApiGetConsultationInfoByTestRequest
 */
export interface TestsApiGetConsultationInfoByTestRequest {
    /**
     * 
     * @type {number}
     * @memberof TestsApiGetConsultationInfoByTest
     */
    readonly testId: number
}

/**
 * Request parameters for getTest operation in TestsApi.
 * @export
 * @interface TestsApiGetTestRequest
 */
export interface TestsApiGetTestRequest {
    /**
     * 
     * @type {number}
     * @memberof TestsApiGetTest
     */
    readonly testId: number
}

/**
 * Request parameters for getTestQuestionnaires operation in TestsApi.
 * @export
 * @interface TestsApiGetTestQuestionnairesRequest
 */
export interface TestsApiGetTestQuestionnairesRequest {
    /**
     * 
     * @type {number}
     * @memberof TestsApiGetTestQuestionnaires
     */
    readonly testId: number
}

/**
 * Request parameters for getTests operation in TestsApi.
 * @export
 * @interface TestsApiGetTestsRequest
 */
export interface TestsApiGetTestsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof TestsApiGetTests
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof TestsApiGetTests
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof TestsApiGetTests
     */
    readonly filter?: string
}

/**
 * Request parameters for updateTest operation in TestsApi.
 * @export
 * @interface TestsApiUpdateTestRequest
 */
export interface TestsApiUpdateTestRequest {
    /**
     * 
     * @type {number}
     * @memberof TestsApiUpdateTest
     */
    readonly testId: number

    /**
     * 
     * @type {TestUpdate}
     * @memberof TestsApiUpdateTest
     */
    readonly testUpdate: TestUpdate
}

/**
 * TestsApi - object-oriented interface
 * @export
 * @class TestsApi
 * @extends {BaseAPI}
 */
export class TestsApi extends BaseAPI {
    /**
     * 
     * @summary Create Test
     * @param {TestsApiCreateTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public createTest(requestParameters: TestsApiCreateTestRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).createTest(requestParameters.testCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Test
     * @param {TestsApiDeleteTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public deleteTest(requestParameters: TestsApiDeleteTestRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).deleteTest(requestParameters.testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consultation Info By Test
     * @param {TestsApiGetConsultationInfoByTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public getConsultationInfoByTest(requestParameters: TestsApiGetConsultationInfoByTestRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).getConsultationInfoByTest(requestParameters.testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Test
     * @param {TestsApiGetTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public getTest(requestParameters: TestsApiGetTestRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).getTest(requestParameters.testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Test Questionnaires
     * @param {TestsApiGetTestQuestionnairesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public getTestQuestionnaires(requestParameters: TestsApiGetTestQuestionnairesRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).getTestQuestionnaires(requestParameters.testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tests
     * @param {TestsApiGetTestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public getTests(requestParameters: TestsApiGetTestsRequest = {}, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).getTests(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Test
     * @param {TestsApiUpdateTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public updateTest(requestParameters: TestsApiUpdateTestRequest, options?: AxiosRequestConfig) {
        return TestsApiFp(this.configuration).updateTest(requestParameters.testId, requestParameters.testUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
