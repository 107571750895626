/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AudiologistCreate } from '../models';
// @ts-ignore
import { AudiologistGet } from '../models';
// @ts-ignore
import { AudiologistList } from '../models';
// @ts-ignore
import { AudiologistUpdate } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * AudiologistsApi - axios parameter creator
 * @export
 */
export const AudiologistsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Audiologist
         * @param {AudiologistCreate} audiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudiologist: async (audiologistCreate: AudiologistCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audiologistCreate' is not null or undefined
            assertParamExists('createAudiologist', 'audiologistCreate', audiologistCreate)
            const localVarPath = `/api/v1/audiologists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audiologistCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudiologist: async (audiologistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audiologistId' is not null or undefined
            assertParamExists('deleteAudiologist', 'audiologistId', audiologistId)
            const localVarPath = `/api/v1/audiologists/{audiologist_id}`
                .replace(`{${"audiologist_id"}}`, encodeURIComponent(String(audiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiologist: async (audiologistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audiologistId' is not null or undefined
            assertParamExists('getAudiologist', 'audiologistId', audiologistId)
            const localVarPath = `/api/v1/audiologists/{audiologist_id}`
                .replace(`{${"audiologist_id"}}`, encodeURIComponent(String(audiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiologists: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/audiologists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Audiologist
         * @param {number} audiologistId 
         * @param {AudiologistUpdate} audiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudiologist: async (audiologistId: number, audiologistUpdate: AudiologistUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audiologistId' is not null or undefined
            assertParamExists('updateAudiologist', 'audiologistId', audiologistId)
            // verify required parameter 'audiologistUpdate' is not null or undefined
            assertParamExists('updateAudiologist', 'audiologistUpdate', audiologistUpdate)
            const localVarPath = `/api/v1/audiologists/{audiologist_id}`
                .replace(`{${"audiologist_id"}}`, encodeURIComponent(String(audiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audiologistUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudiologistsApi - functional programming interface
 * @export
 */
export const AudiologistsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudiologistsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Audiologist
         * @param {AudiologistCreate} audiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAudiologist(audiologistCreate: AudiologistCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudiologistGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAudiologist(audiologistCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAudiologist(audiologistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAudiologist(audiologistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudiologist(audiologistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudiologistGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudiologist(audiologistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Audiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudiologists(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudiologistList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudiologists(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Audiologist
         * @param {number} audiologistId 
         * @param {AudiologistUpdate} audiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAudiologist(audiologistId: number, audiologistUpdate: AudiologistUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudiologistGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAudiologist(audiologistId, audiologistUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudiologistsApi - factory interface
 * @export
 */
export const AudiologistsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudiologistsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Audiologist
         * @param {AudiologistCreate} audiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudiologist(audiologistCreate: AudiologistCreate, options?: any): AxiosPromise<AudiologistGet> {
            return localVarFp.createAudiologist(audiologistCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudiologist(audiologistId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteAudiologist(audiologistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Audiologist
         * @param {number} audiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiologist(audiologistId: number, options?: any): AxiosPromise<AudiologistGet> {
            return localVarFp.getAudiologist(audiologistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Audiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiologists(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<AudiologistList>> {
            return localVarFp.getAudiologists(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Audiologist
         * @param {number} audiologistId 
         * @param {AudiologistUpdate} audiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudiologist(audiologistId: number, audiologistUpdate: AudiologistUpdate, options?: any): AxiosPromise<AudiologistGet> {
            return localVarFp.updateAudiologist(audiologistId, audiologistUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAudiologist operation in AudiologistsApi.
 * @export
 * @interface AudiologistsApiCreateAudiologistRequest
 */
export interface AudiologistsApiCreateAudiologistRequest {
    /**
     * 
     * @type {AudiologistCreate}
     * @memberof AudiologistsApiCreateAudiologist
     */
    readonly audiologistCreate: AudiologistCreate
}

/**
 * Request parameters for deleteAudiologist operation in AudiologistsApi.
 * @export
 * @interface AudiologistsApiDeleteAudiologistRequest
 */
export interface AudiologistsApiDeleteAudiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof AudiologistsApiDeleteAudiologist
     */
    readonly audiologistId: number
}

/**
 * Request parameters for getAudiologist operation in AudiologistsApi.
 * @export
 * @interface AudiologistsApiGetAudiologistRequest
 */
export interface AudiologistsApiGetAudiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof AudiologistsApiGetAudiologist
     */
    readonly audiologistId: number
}

/**
 * Request parameters for getAudiologists operation in AudiologistsApi.
 * @export
 * @interface AudiologistsApiGetAudiologistsRequest
 */
export interface AudiologistsApiGetAudiologistsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof AudiologistsApiGetAudiologists
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof AudiologistsApiGetAudiologists
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof AudiologistsApiGetAudiologists
     */
    readonly filter?: string
}

/**
 * Request parameters for updateAudiologist operation in AudiologistsApi.
 * @export
 * @interface AudiologistsApiUpdateAudiologistRequest
 */
export interface AudiologistsApiUpdateAudiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof AudiologistsApiUpdateAudiologist
     */
    readonly audiologistId: number

    /**
     * 
     * @type {AudiologistUpdate}
     * @memberof AudiologistsApiUpdateAudiologist
     */
    readonly audiologistUpdate: AudiologistUpdate
}

/**
 * AudiologistsApi - object-oriented interface
 * @export
 * @class AudiologistsApi
 * @extends {BaseAPI}
 */
export class AudiologistsApi extends BaseAPI {
    /**
     * 
     * @summary Create Audiologist
     * @param {AudiologistsApiCreateAudiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiologistsApi
     */
    public createAudiologist(requestParameters: AudiologistsApiCreateAudiologistRequest, options?: AxiosRequestConfig) {
        return AudiologistsApiFp(this.configuration).createAudiologist(requestParameters.audiologistCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Audiologist
     * @param {AudiologistsApiDeleteAudiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiologistsApi
     */
    public deleteAudiologist(requestParameters: AudiologistsApiDeleteAudiologistRequest, options?: AxiosRequestConfig) {
        return AudiologistsApiFp(this.configuration).deleteAudiologist(requestParameters.audiologistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Audiologist
     * @param {AudiologistsApiGetAudiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiologistsApi
     */
    public getAudiologist(requestParameters: AudiologistsApiGetAudiologistRequest, options?: AxiosRequestConfig) {
        return AudiologistsApiFp(this.configuration).getAudiologist(requestParameters.audiologistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Audiologists
     * @param {AudiologistsApiGetAudiologistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiologistsApi
     */
    public getAudiologists(requestParameters: AudiologistsApiGetAudiologistsRequest = {}, options?: AxiosRequestConfig) {
        return AudiologistsApiFp(this.configuration).getAudiologists(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Audiologist
     * @param {AudiologistsApiUpdateAudiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiologistsApi
     */
    public updateAudiologist(requestParameters: AudiologistsApiUpdateAudiologistRequest, options?: AxiosRequestConfig) {
        return AudiologistsApiFp(this.configuration).updateAudiologist(requestParameters.audiologistId, requestParameters.audiologistUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
