/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { PatientCreate } from '../models';
// @ts-ignore
import { PatientGet } from '../models';
// @ts-ignore
import { PatientList } from '../models';
// @ts-ignore
import { PatientUpdate } from '../models';
/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Patient
         * @param {PatientCreate} patientCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient: async (patientCreate: PatientCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientCreate' is not null or undefined
            assertParamExists('createPatient', 'patientCreate', patientCreate)
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatient', 'patientId', patientId)
            const localVarPath = `/api/v1/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Visits
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientVisits: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientVisits', 'patientId', patientId)
            const localVarPath = `/api/v1/patients/{patient_id}/visits`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patients
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatients: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Patients Contactnumber
         * @param {string} contactNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsContactNumber: async (contactNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactNumber' is not null or undefined
            assertParamExists('searchPatientsContactNumber', 'contactNumber', contactNumber)
            const localVarPath = `/api/v1/patients/search/{contact_number}`
                .replace(`{${"contact_number"}}`, encodeURIComponent(String(contactNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient: async (patientId: number, patientUpdate: PatientUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatient', 'patientId', patientId)
            // verify required parameter 'patientUpdate' is not null or undefined
            assertParamExists('updatePatient', 'patientUpdate', patientUpdate)
            const localVarPath = `/api/v1/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Patient
         * @param {PatientCreate} patientCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatient(patientCreate: PatientCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatient(patientCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Patient
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Patient Visits
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientVisits(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientVisits(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Patients
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatients(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatients(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Patients Contactnumber
         * @param {string} contactNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPatientsContactNumber(contactNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPatientsContactNumber(contactNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatient(patientId: number, patientUpdate: PatientUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatient(patientId, patientUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Patient
         * @param {PatientCreate} patientCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient(patientCreate: PatientCreate, options?: any): AxiosPromise<PatientGet> {
            return localVarFp.createPatient(patientCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient(patientId: number, options?: any): AxiosPromise<PatientGet> {
            return localVarFp.getPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Visits
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientVisits(patientId: number, options?: any): AxiosPromise<PatientGet> {
            return localVarFp.getPatientVisits(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patients
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatients(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<PatientList>> {
            return localVarFp.getPatients(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Patients Contactnumber
         * @param {string} contactNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsContactNumber(contactNumber: string, options?: any): AxiosPromise<Array<PatientList>> {
            return localVarFp.searchPatientsContactNumber(contactNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(patientId: number, patientUpdate: PatientUpdate, options?: any): AxiosPromise<PatientGet> {
            return localVarFp.updatePatient(patientId, patientUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPatient operation in PatientsApi.
 * @export
 * @interface PatientsApiCreatePatientRequest
 */
export interface PatientsApiCreatePatientRequest {
    /**
     * 
     * @type {PatientCreate}
     * @memberof PatientsApiCreatePatient
     */
    readonly patientCreate: PatientCreate
}

/**
 * Request parameters for getPatient operation in PatientsApi.
 * @export
 * @interface PatientsApiGetPatientRequest
 */
export interface PatientsApiGetPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientsApiGetPatient
     */
    readonly patientId: number
}

/**
 * Request parameters for getPatientVisits operation in PatientsApi.
 * @export
 * @interface PatientsApiGetPatientVisitsRequest
 */
export interface PatientsApiGetPatientVisitsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientsApiGetPatientVisits
     */
    readonly patientId: number
}

/**
 * Request parameters for getPatients operation in PatientsApi.
 * @export
 * @interface PatientsApiGetPatientsRequest
 */
export interface PatientsApiGetPatientsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof PatientsApiGetPatients
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof PatientsApiGetPatients
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof PatientsApiGetPatients
     */
    readonly filter?: string
}

/**
 * Request parameters for searchPatientsContactNumber operation in PatientsApi.
 * @export
 * @interface PatientsApiSearchPatientsContactNumberRequest
 */
export interface PatientsApiSearchPatientsContactNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof PatientsApiSearchPatientsContactNumber
     */
    readonly contactNumber: string
}

/**
 * Request parameters for updatePatient operation in PatientsApi.
 * @export
 * @interface PatientsApiUpdatePatientRequest
 */
export interface PatientsApiUpdatePatientRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientsApiUpdatePatient
     */
    readonly patientId: number

    /**
     * 
     * @type {PatientUpdate}
     * @memberof PatientsApiUpdatePatient
     */
    readonly patientUpdate: PatientUpdate
}

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * 
     * @summary Create Patient
     * @param {PatientsApiCreatePatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createPatient(requestParameters: PatientsApiCreatePatientRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).createPatient(requestParameters.patientCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient
     * @param {PatientsApiGetPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getPatient(requestParameters: PatientsApiGetPatientRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getPatient(requestParameters.patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Visits
     * @param {PatientsApiGetPatientVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getPatientVisits(requestParameters: PatientsApiGetPatientVisitsRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getPatientVisits(requestParameters.patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patients
     * @param {PatientsApiGetPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getPatients(requestParameters: PatientsApiGetPatientsRequest = {}, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getPatients(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Patients Contactnumber
     * @param {PatientsApiSearchPatientsContactNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public searchPatientsContactNumber(requestParameters: PatientsApiSearchPatientsContactNumberRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).searchPatientsContactNumber(requestParameters.contactNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient
     * @param {PatientsApiUpdatePatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public updatePatient(requestParameters: PatientsApiUpdatePatientRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).updatePatient(requestParameters.patientId, requestParameters.patientUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
