// project import
import services from 'utils/mockAdapter';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

export const dashboard: NavItemType = {
  id: 'group-dashboard',
  title: 'dashboard',
  type: 'group',
  icon: 'dashboardOutlined',
  children: [
    {
      id: 'dashboard',
      title: 'dashboard',
      type: 'collapse',
      icon: 'dashboardOutlined',
      children: [
        {
          id: 'dashboard',
          title: 'dashboard',
          type: 'item',
          url: '/dashboard',
          breadcrumbs: false
        },
        // {
        //   id: 'admin',
        //   title: 'Admin',
        //   type: 'item',
        //   url: '/dashboard/admin',
        //   breadcrumbs: false
        // }
      ]
    } 
  ]
};
 
// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard: dashboard });
