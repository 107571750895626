/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { StateCreate } from '../models';
// @ts-ignore
import { StateList } from '../models';
// @ts-ignore
import { StateUpdate } from '../models';
/**
 * StatesApi - axios parameter creator
 * @export
 */
export const StatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create State
         * @param {StateCreate} stateCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createState: async (stateCreate: StateCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateCreate' is not null or undefined
            assertParamExists('createState', 'stateCreate', stateCreate)
            const localVarPath = `/api/v1/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stateCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete State
         * @param {number} stateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteState: async (stateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateId' is not null or undefined
            assertParamExists('deleteState', 'stateId', stateId)
            const localVarPath = `/api/v1/states/{state_id}`
                .replace(`{${"state_id"}}`, encodeURIComponent(String(stateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get States
         * @param {number} countryId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStates: async (countryId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('getStates', 'countryId', countryId)
            const localVarPath = `/api/v1/states/{country_id}`
                .replace(`{${"country_id"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update State
         * @param {number} stateId 
         * @param {StateUpdate} stateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateState: async (stateId: number, stateUpdate: StateUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateId' is not null or undefined
            assertParamExists('updateState', 'stateId', stateId)
            // verify required parameter 'stateUpdate' is not null or undefined
            assertParamExists('updateState', 'stateUpdate', stateUpdate)
            const localVarPath = `/api/v1/states/{state_id}`
                .replace(`{${"state_id"}}`, encodeURIComponent(String(stateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stateUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatesApi - functional programming interface
 * @export
 */
export const StatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create State
         * @param {StateCreate} stateCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createState(stateCreate: StateCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createState(stateCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete State
         * @param {number} stateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteState(stateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteState(stateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get States
         * @param {number} countryId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStates(countryId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStates(countryId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update State
         * @param {number} stateId 
         * @param {StateUpdate} stateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateState(stateId: number, stateUpdate: StateUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateState(stateId, stateUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatesApi - factory interface
 * @export
 */
export const StatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create State
         * @param {StateCreate} stateCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createState(stateCreate: StateCreate, options?: any): AxiosPromise<StateList> {
            return localVarFp.createState(stateCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete State
         * @param {number} stateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteState(stateId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteState(stateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get States
         * @param {number} countryId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStates(countryId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<StateList>> {
            return localVarFp.getStates(countryId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update State
         * @param {number} stateId 
         * @param {StateUpdate} stateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateState(stateId: number, stateUpdate: StateUpdate, options?: any): AxiosPromise<StateList> {
            return localVarFp.updateState(stateId, stateUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createState operation in StatesApi.
 * @export
 * @interface StatesApiCreateStateRequest
 */
export interface StatesApiCreateStateRequest {
    /**
     * 
     * @type {StateCreate}
     * @memberof StatesApiCreateState
     */
    readonly stateCreate: StateCreate
}

/**
 * Request parameters for deleteState operation in StatesApi.
 * @export
 * @interface StatesApiDeleteStateRequest
 */
export interface StatesApiDeleteStateRequest {
    /**
     * 
     * @type {number}
     * @memberof StatesApiDeleteState
     */
    readonly stateId: number
}

/**
 * Request parameters for getStates operation in StatesApi.
 * @export
 * @interface StatesApiGetStatesRequest
 */
export interface StatesApiGetStatesRequest {
    /**
     * 
     * @type {number}
     * @memberof StatesApiGetStates
     */
    readonly countryId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StatesApiGetStates
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StatesApiGetStates
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StatesApiGetStates
     */
    readonly filter?: string
}

/**
 * Request parameters for updateState operation in StatesApi.
 * @export
 * @interface StatesApiUpdateStateRequest
 */
export interface StatesApiUpdateStateRequest {
    /**
     * 
     * @type {number}
     * @memberof StatesApiUpdateState
     */
    readonly stateId: number

    /**
     * 
     * @type {StateUpdate}
     * @memberof StatesApiUpdateState
     */
    readonly stateUpdate: StateUpdate
}

/**
 * StatesApi - object-oriented interface
 * @export
 * @class StatesApi
 * @extends {BaseAPI}
 */
export class StatesApi extends BaseAPI {
    /**
     * 
     * @summary Create State
     * @param {StatesApiCreateStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatesApi
     */
    public createState(requestParameters: StatesApiCreateStateRequest, options?: AxiosRequestConfig) {
        return StatesApiFp(this.configuration).createState(requestParameters.stateCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete State
     * @param {StatesApiDeleteStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatesApi
     */
    public deleteState(requestParameters: StatesApiDeleteStateRequest, options?: AxiosRequestConfig) {
        return StatesApiFp(this.configuration).deleteState(requestParameters.stateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get States
     * @param {StatesApiGetStatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatesApi
     */
    public getStates(requestParameters: StatesApiGetStatesRequest, options?: AxiosRequestConfig) {
        return StatesApiFp(this.configuration).getStates(requestParameters.countryId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update State
     * @param {StatesApiUpdateStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatesApi
     */
    public updateState(requestParameters: StatesApiUpdateStateRequest, options?: AxiosRequestConfig) {
        return StatesApiFp(this.configuration).updateState(requestParameters.stateId, requestParameters.stateUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
