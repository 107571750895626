import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import  
import Header from 'layout/MainLayout/Header';
import Footer from './Footer';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';

import navigation from '../../menu-items';
import useConfig from '../../hooks/useConfig';

// types
import { RootStateProps } from '../../types/root';
import { LAYOUT_CONST } from '../../types/config';

// ==============================|| MAIN LAYOUT ||============================== //

const VideoConsultation = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation } = useConfig();
  const dispatch = useDispatch();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;


  return (
    <Box sx={{ display: 'flex', width: '100%', p: 0 }}>
      {/* <Header open={false} /> */}
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: 0 }}>
        {/* <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} /> */}
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { p: 0 }),
            position: 'relative',
            minHeight: 'calc(100vh - 50px)',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
          }}
        >
          {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default VideoConsultation;
