import { AuthApi, Configuration, UsersApi, PatientsApi, CentresApi, StatesApi, CitiesApi, CountriesApi, AudiologistsApi, ConsultationrequestsApi, QuestionnairegroupsApi, QuestionnairesApi, TestsApi, TestresultsApi, TestquestionnairesApi, AnalyticsApi } from "../generated";
const readApiBaseFromEnv = (): string => {
  // Get API base URL from env
  // Priority is given to same host in the browser when environemnt is production
  if (
    process.env.NODE_ENV === "production" &&
    !document.location.host.startsWith("localhost")
  ) {
    return `https://${document.location.host}`;
  } else if (process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  }
  return "http://localhost:8000";
};

const readAccessToken = async (): Promise<string> => {
  // return localStorage.getItem("token") || "";
  return localStorage.getItem("serviceToken") || "";
};

export const basePath: string = readApiBaseFromEnv();

const apiConfig: Configuration = new Configuration({
  basePath,
  accessToken: readAccessToken,
});

export const authApi: AuthApi = new AuthApi(apiConfig);
export const userApi: UsersApi = new UsersApi(apiConfig);
export const centresApi: CentresApi = new CentresApi(apiConfig);
export const audiologistsApi: AudiologistsApi = new AudiologistsApi(apiConfig);
export const patientsApi: PatientsApi = new PatientsApi(apiConfig);
export const testsApi: TestsApi = new TestsApi(apiConfig);
export const testResultsApi: TestresultsApi = new TestresultsApi(apiConfig);
export const testquestionnairesApi: TestquestionnairesApi = new TestquestionnairesApi(apiConfig);
export const countriesApi: CountriesApi = new CountriesApi(apiConfig);
export const statesApi: StatesApi = new StatesApi(apiConfig);
export const citiesApi: CitiesApi = new CitiesApi(apiConfig);
export const consultationrequestsApi: ConsultationrequestsApi = new ConsultationrequestsApi(apiConfig);
export const questionnairesApi: QuestionnairesApi = new QuestionnairesApi(apiConfig);
export const questionnairegroupsApi: QuestionnairegroupsApi = new QuestionnairegroupsApi(apiConfig);
export const analyticsApi: AnalyticsApi = new AnalyticsApi(apiConfig);


