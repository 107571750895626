/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { TestDocumentsCreate } from '../models';
// @ts-ignore
import { TestDocumentsList } from '../models';
// @ts-ignore
import { TestDocumentsUpdate } from '../models';
/**
 * TestdocumentsApi - axios parameter creator
 * @export
 */
export const TestdocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Testdocument
         * @param {TestDocumentsCreate} testDocumentsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestdocument: async (testDocumentsCreate: TestDocumentsCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testDocumentsCreate' is not null or undefined
            assertParamExists('createTestdocument', 'testDocumentsCreate', testDocumentsCreate)
            const localVarPath = `/api/v1/testdocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testDocumentsCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestdocument: async (testdocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testdocumentId' is not null or undefined
            assertParamExists('deleteTestdocument', 'testdocumentId', testdocumentId)
            const localVarPath = `/api/v1/testdocuments/{testdocument_id}`
                .replace(`{${"testdocument_id"}}`, encodeURIComponent(String(testdocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestdocument: async (testdocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testdocumentId' is not null or undefined
            assertParamExists('getTestdocument', 'testdocumentId', testdocumentId)
            const localVarPath = `/api/v1/testdocuments/{testdocument_id}`
                .replace(`{${"testdocument_id"}}`, encodeURIComponent(String(testdocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testdocuments
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestdocuments: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/testdocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testdocument
         * @param {number} testdocumentId 
         * @param {TestDocumentsUpdate} testDocumentsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestdocument: async (testdocumentId: number, testDocumentsUpdate: TestDocumentsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testdocumentId' is not null or undefined
            assertParamExists('updateTestdocument', 'testdocumentId', testdocumentId)
            // verify required parameter 'testDocumentsUpdate' is not null or undefined
            assertParamExists('updateTestdocument', 'testDocumentsUpdate', testDocumentsUpdate)
            const localVarPath = `/api/v1/testdocuments/{testdocument_id}`
                .replace(`{${"testdocument_id"}}`, encodeURIComponent(String(testdocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testDocumentsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestdocumentsApi - functional programming interface
 * @export
 */
export const TestdocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestdocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Testdocument
         * @param {TestDocumentsCreate} testDocumentsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestdocument(testDocumentsCreate: TestDocumentsCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestDocumentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestdocument(testDocumentsCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestdocument(testdocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestdocument(testdocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestdocument(testdocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestDocumentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestdocument(testdocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testdocuments
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestdocuments(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestDocumentsList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestdocuments(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Testdocument
         * @param {number} testdocumentId 
         * @param {TestDocumentsUpdate} testDocumentsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestdocument(testdocumentId: number, testDocumentsUpdate: TestDocumentsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestDocumentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestdocument(testdocumentId, testDocumentsUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestdocumentsApi - factory interface
 * @export
 */
export const TestdocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestdocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Testdocument
         * @param {TestDocumentsCreate} testDocumentsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestdocument(testDocumentsCreate: TestDocumentsCreate, options?: any): AxiosPromise<TestDocumentsList> {
            return localVarFp.createTestdocument(testDocumentsCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestdocument(testdocumentId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTestdocument(testdocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testdocument
         * @param {number} testdocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestdocument(testdocumentId: number, options?: any): AxiosPromise<TestDocumentsList> {
            return localVarFp.getTestdocument(testdocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testdocuments
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestdocuments(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<TestDocumentsList>> {
            return localVarFp.getTestdocuments(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testdocument
         * @param {number} testdocumentId 
         * @param {TestDocumentsUpdate} testDocumentsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestdocument(testdocumentId: number, testDocumentsUpdate: TestDocumentsUpdate, options?: any): AxiosPromise<TestDocumentsList> {
            return localVarFp.updateTestdocument(testdocumentId, testDocumentsUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTestdocument operation in TestdocumentsApi.
 * @export
 * @interface TestdocumentsApiCreateTestdocumentRequest
 */
export interface TestdocumentsApiCreateTestdocumentRequest {
    /**
     * 
     * @type {TestDocumentsCreate}
     * @memberof TestdocumentsApiCreateTestdocument
     */
    readonly testDocumentsCreate: TestDocumentsCreate
}

/**
 * Request parameters for deleteTestdocument operation in TestdocumentsApi.
 * @export
 * @interface TestdocumentsApiDeleteTestdocumentRequest
 */
export interface TestdocumentsApiDeleteTestdocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof TestdocumentsApiDeleteTestdocument
     */
    readonly testdocumentId: number
}

/**
 * Request parameters for getTestdocument operation in TestdocumentsApi.
 * @export
 * @interface TestdocumentsApiGetTestdocumentRequest
 */
export interface TestdocumentsApiGetTestdocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof TestdocumentsApiGetTestdocument
     */
    readonly testdocumentId: number
}

/**
 * Request parameters for getTestdocuments operation in TestdocumentsApi.
 * @export
 * @interface TestdocumentsApiGetTestdocumentsRequest
 */
export interface TestdocumentsApiGetTestdocumentsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof TestdocumentsApiGetTestdocuments
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof TestdocumentsApiGetTestdocuments
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof TestdocumentsApiGetTestdocuments
     */
    readonly filter?: string
}

/**
 * Request parameters for updateTestdocument operation in TestdocumentsApi.
 * @export
 * @interface TestdocumentsApiUpdateTestdocumentRequest
 */
export interface TestdocumentsApiUpdateTestdocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof TestdocumentsApiUpdateTestdocument
     */
    readonly testdocumentId: number

    /**
     * 
     * @type {TestDocumentsUpdate}
     * @memberof TestdocumentsApiUpdateTestdocument
     */
    readonly testDocumentsUpdate: TestDocumentsUpdate
}

/**
 * TestdocumentsApi - object-oriented interface
 * @export
 * @class TestdocumentsApi
 * @extends {BaseAPI}
 */
export class TestdocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Create Testdocument
     * @param {TestdocumentsApiCreateTestdocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestdocumentsApi
     */
    public createTestdocument(requestParameters: TestdocumentsApiCreateTestdocumentRequest, options?: AxiosRequestConfig) {
        return TestdocumentsApiFp(this.configuration).createTestdocument(requestParameters.testDocumentsCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Testdocument
     * @param {TestdocumentsApiDeleteTestdocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestdocumentsApi
     */
    public deleteTestdocument(requestParameters: TestdocumentsApiDeleteTestdocumentRequest, options?: AxiosRequestConfig) {
        return TestdocumentsApiFp(this.configuration).deleteTestdocument(requestParameters.testdocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testdocument
     * @param {TestdocumentsApiGetTestdocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestdocumentsApi
     */
    public getTestdocument(requestParameters: TestdocumentsApiGetTestdocumentRequest, options?: AxiosRequestConfig) {
        return TestdocumentsApiFp(this.configuration).getTestdocument(requestParameters.testdocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testdocuments
     * @param {TestdocumentsApiGetTestdocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestdocumentsApi
     */
    public getTestdocuments(requestParameters: TestdocumentsApiGetTestdocumentsRequest = {}, options?: AxiosRequestConfig) {
        return TestdocumentsApiFp(this.configuration).getTestdocuments(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testdocument
     * @param {TestdocumentsApiUpdateTestdocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestdocumentsApi
     */
    public updateTestdocument(requestParameters: TestdocumentsApiUpdateTestdocumentRequest, options?: AxiosRequestConfig) {
        return TestdocumentsApiFp(this.configuration).updateTestdocument(requestParameters.testdocumentId, requestParameters.testDocumentsUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
