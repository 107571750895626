
export const CENTRES_CARDS_DISPLAY_SIZE = 24
export const CENTRES_LIST_DISPLAY_SIZE = 24
export const AUDIOLOGISTS_CARDS_DISPLAY_SIZE = 24
export const AUDIOLOGISTS_LIST_DISPLAY_SIZE = 24
export const CONSULTATION_REQUEST_CARDS_DISPLAY_SIZE = 60
export const CONSULTATION_HISTORY_DISPLAY_SIZE = 60

export const CONSULTATION_RECORDING_DURATION = 5 // IN MIN.

export enum SOCKET_EVENTS {
    room_join = 'room_join',
    room_joined = 'room_joined',
    connect = 'connect',
    connect_error = 'connect_error',
    connect_timeout = 'connect_timeout',
    message_receive = 'message_receive',
    message_reply = 'message_reply',
    disconnect = 'disconnect',
    reconnect = 'reconnect',
    room_leave = 'room_leave'
 }

