//contexts/SockeetContext.tsx
import { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import JWTContext from 'contexts/JWTContext';
import { SOCKET_EVENTS } from 'utils/constants';
 

const SocketContext = createContext<Socket | null>(null);

interface SocketProviderProps {
    children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const jwt = useContext(JWTContext);
    const socketServerUrl = process.env.REACT_APP_SOCKET_ENDPOINT as string || "http://localhost:8000";
    // const socketServerUrl = "http://localhost:8000"

    useEffect(() => {
        if (jwt?.isLoggedIn) {
            // debugger;
            // const newSocket = io(socketServerUrl, {
            //     path: '/ws/socket.io',
            //     auth: {
            //         token: localStorage.getItem('serviceToken')
            //     }
            // }); 
            const newSocket = io(socketServerUrl, { path: '/ws/socket.io' }); 

            newSocket.on(SOCKET_EVENTS.connect, () => {
                console.log('Connected to the server');
            }); 

            newSocket.on(SOCKET_EVENTS.disconnect, (reason) => {
                console.log(`Disconnected due to: ${reason}`);
                if (reason === 'io server disconnect') {
                    newSocket.connect(); // If disconnected by the server, attempt to reconnect
                }
            });

            newSocket.on(SOCKET_EVENTS.connect_error, (error) => {
                // console.error('Connection error:', error);
                console.error('Connection error');
            });

            newSocket.on(SOCKET_EVENTS.connect_timeout, () => {
                console.error('Connection timeout');
            });

            newSocket.on(SOCKET_EVENTS.reconnect, (attemptNumber) => {
                console.log(`Reconnected on attempt ${attemptNumber}`);
            });

            setSocket(newSocket);

            return () => {
                // Cleanup listeners
                newSocket.off(SOCKET_EVENTS.connect);
                newSocket.off(SOCKET_EVENTS.disconnect);
                newSocket.off(SOCKET_EVENTS.connect_error);
                newSocket.off(SOCKET_EVENTS.connect_timeout);
                newSocket.off(SOCKET_EVENTS.reconnect);
                newSocket.disconnect();
                newSocket.close();
            };
        }
    }, [jwt?.isLoggedIn, socketServerUrl]);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
