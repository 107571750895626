/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { QuestionnaireGroupCreate } from '../models';
// @ts-ignore
import { QuestionnaireGroupGet } from '../models';
// @ts-ignore
import { QuestionnaireGroupList } from '../models';
// @ts-ignore
import { QuestionnaireGroupUpdate } from '../models';
/**
 * QuestionnairegroupsApi - axios parameter creator
 * @export
 */
export const QuestionnairegroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Questionnairegroup
         * @param {QuestionnaireGroupCreate} questionnaireGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnairegroup: async (questionnaireGroupCreate: QuestionnaireGroupCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireGroupCreate' is not null or undefined
            assertParamExists('createQuestionnairegroup', 'questionnaireGroupCreate', questionnaireGroupCreate)
            const localVarPath = `/api/v1/questionnairegroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireGroupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnairegroup: async (questionnairegroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnairegroupId' is not null or undefined
            assertParamExists('deleteQuestionnairegroup', 'questionnairegroupId', questionnairegroupId)
            const localVarPath = `/api/v1/questionnairegroups/{questionnairegroup_id}`
                .replace(`{${"questionnairegroup_id"}}`, encodeURIComponent(String(questionnairegroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnairegroup: async (questionnairegroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnairegroupId' is not null or undefined
            assertParamExists('getQuestionnairegroup', 'questionnairegroupId', questionnairegroupId)
            const localVarPath = `/api/v1/questionnairegroups/{questionnairegroup_id}`
                .replace(`{${"questionnairegroup_id"}}`, encodeURIComponent(String(questionnairegroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnairegroups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnairegroups: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/questionnairegroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {QuestionnaireGroupUpdate} questionnaireGroupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnairegroup: async (questionnairegroupId: number, questionnaireGroupUpdate: QuestionnaireGroupUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnairegroupId' is not null or undefined
            assertParamExists('updateQuestionnairegroup', 'questionnairegroupId', questionnairegroupId)
            // verify required parameter 'questionnaireGroupUpdate' is not null or undefined
            assertParamExists('updateQuestionnairegroup', 'questionnaireGroupUpdate', questionnaireGroupUpdate)
            const localVarPath = `/api/v1/questionnairegroups/{questionnairegroup_id}`
                .replace(`{${"questionnairegroup_id"}}`, encodeURIComponent(String(questionnairegroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireGroupUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnairegroupsApi - functional programming interface
 * @export
 */
export const QuestionnairegroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnairegroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Questionnairegroup
         * @param {QuestionnaireGroupCreate} questionnaireGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnairegroup(questionnaireGroupCreate: QuestionnaireGroupCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnairegroup(questionnaireGroupCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnairegroup(questionnairegroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnairegroup(questionnairegroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnairegroup(questionnairegroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireGroupGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnairegroup(questionnairegroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnairegroups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnairegroups(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionnaireGroupList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnairegroups(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {QuestionnaireGroupUpdate} questionnaireGroupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionnairegroup(questionnairegroupId: number, questionnaireGroupUpdate: QuestionnaireGroupUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionnairegroup(questionnairegroupId, questionnaireGroupUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnairegroupsApi - factory interface
 * @export
 */
export const QuestionnairegroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnairegroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Questionnairegroup
         * @param {QuestionnaireGroupCreate} questionnaireGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnairegroup(questionnaireGroupCreate: QuestionnaireGroupCreate, options?: any): AxiosPromise<QuestionnaireGroupList> {
            return localVarFp.createQuestionnairegroup(questionnaireGroupCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnairegroup(questionnairegroupId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteQuestionnairegroup(questionnairegroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnairegroup(questionnairegroupId: number, options?: any): AxiosPromise<QuestionnaireGroupGet> {
            return localVarFp.getQuestionnairegroup(questionnairegroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnairegroups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnairegroups(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<QuestionnaireGroupList>> {
            return localVarFp.getQuestionnairegroups(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Questionnairegroup
         * @param {number} questionnairegroupId 
         * @param {QuestionnaireGroupUpdate} questionnaireGroupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnairegroup(questionnairegroupId: number, questionnaireGroupUpdate: QuestionnaireGroupUpdate, options?: any): AxiosPromise<QuestionnaireGroupList> {
            return localVarFp.updateQuestionnairegroup(questionnairegroupId, questionnaireGroupUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createQuestionnairegroup operation in QuestionnairegroupsApi.
 * @export
 * @interface QuestionnairegroupsApiCreateQuestionnairegroupRequest
 */
export interface QuestionnairegroupsApiCreateQuestionnairegroupRequest {
    /**
     * 
     * @type {QuestionnaireGroupCreate}
     * @memberof QuestionnairegroupsApiCreateQuestionnairegroup
     */
    readonly questionnaireGroupCreate: QuestionnaireGroupCreate
}

/**
 * Request parameters for deleteQuestionnairegroup operation in QuestionnairegroupsApi.
 * @export
 * @interface QuestionnairegroupsApiDeleteQuestionnairegroupRequest
 */
export interface QuestionnairegroupsApiDeleteQuestionnairegroupRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnairegroupsApiDeleteQuestionnairegroup
     */
    readonly questionnairegroupId: number
}

/**
 * Request parameters for getQuestionnairegroup operation in QuestionnairegroupsApi.
 * @export
 * @interface QuestionnairegroupsApiGetQuestionnairegroupRequest
 */
export interface QuestionnairegroupsApiGetQuestionnairegroupRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnairegroupsApiGetQuestionnairegroup
     */
    readonly questionnairegroupId: number
}

/**
 * Request parameters for getQuestionnairegroups operation in QuestionnairegroupsApi.
 * @export
 * @interface QuestionnairegroupsApiGetQuestionnairegroupsRequest
 */
export interface QuestionnairegroupsApiGetQuestionnairegroupsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof QuestionnairegroupsApiGetQuestionnairegroups
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof QuestionnairegroupsApiGetQuestionnairegroups
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof QuestionnairegroupsApiGetQuestionnairegroups
     */
    readonly filter?: string
}

/**
 * Request parameters for updateQuestionnairegroup operation in QuestionnairegroupsApi.
 * @export
 * @interface QuestionnairegroupsApiUpdateQuestionnairegroupRequest
 */
export interface QuestionnairegroupsApiUpdateQuestionnairegroupRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnairegroupsApiUpdateQuestionnairegroup
     */
    readonly questionnairegroupId: number

    /**
     * 
     * @type {QuestionnaireGroupUpdate}
     * @memberof QuestionnairegroupsApiUpdateQuestionnairegroup
     */
    readonly questionnaireGroupUpdate: QuestionnaireGroupUpdate
}

/**
 * QuestionnairegroupsApi - object-oriented interface
 * @export
 * @class QuestionnairegroupsApi
 * @extends {BaseAPI}
 */
export class QuestionnairegroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create Questionnairegroup
     * @param {QuestionnairegroupsApiCreateQuestionnairegroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairegroupsApi
     */
    public createQuestionnairegroup(requestParameters: QuestionnairegroupsApiCreateQuestionnairegroupRequest, options?: AxiosRequestConfig) {
        return QuestionnairegroupsApiFp(this.configuration).createQuestionnairegroup(requestParameters.questionnaireGroupCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Questionnairegroup
     * @param {QuestionnairegroupsApiDeleteQuestionnairegroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairegroupsApi
     */
    public deleteQuestionnairegroup(requestParameters: QuestionnairegroupsApiDeleteQuestionnairegroupRequest, options?: AxiosRequestConfig) {
        return QuestionnairegroupsApiFp(this.configuration).deleteQuestionnairegroup(requestParameters.questionnairegroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnairegroup
     * @param {QuestionnairegroupsApiGetQuestionnairegroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairegroupsApi
     */
    public getQuestionnairegroup(requestParameters: QuestionnairegroupsApiGetQuestionnairegroupRequest, options?: AxiosRequestConfig) {
        return QuestionnairegroupsApiFp(this.configuration).getQuestionnairegroup(requestParameters.questionnairegroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnairegroups
     * @param {QuestionnairegroupsApiGetQuestionnairegroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairegroupsApi
     */
    public getQuestionnairegroups(requestParameters: QuestionnairegroupsApiGetQuestionnairegroupsRequest = {}, options?: AxiosRequestConfig) {
        return QuestionnairegroupsApiFp(this.configuration).getQuestionnairegroups(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Questionnairegroup
     * @param {QuestionnairegroupsApiUpdateQuestionnairegroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairegroupsApi
     */
    public updateQuestionnairegroup(requestParameters: QuestionnairegroupsApiUpdateQuestionnairegroupRequest, options?: AxiosRequestConfig) {
        return QuestionnairegroupsApiFp(this.configuration).updateQuestionnairegroup(requestParameters.questionnairegroupId, requestParameters.questionnaireGroupUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
