/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CityCreate } from '../models';
// @ts-ignore
import { CityList } from '../models';
// @ts-ignore
import { CityUpdate } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * CitiesApi - axios parameter creator
 * @export
 */
export const CitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create City
         * @param {CityCreate} cityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCity: async (cityCreate: CityCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityCreate' is not null or undefined
            assertParamExists('createCity', 'cityCreate', cityCreate)
            const localVarPath = `/api/v1/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cityCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete City
         * @param {number} cityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCity: async (cityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityId' is not null or undefined
            assertParamExists('deleteCity', 'cityId', cityId)
            const localVarPath = `/api/v1/cities/{city_id}`
                .replace(`{${"city_id"}}`, encodeURIComponent(String(cityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cities
         * @param {number} stateId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities: async (stateId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateId' is not null or undefined
            assertParamExists('getCities', 'stateId', stateId)
            const localVarPath = `/api/v1/cities/{state_id}`
                .replace(`{${"state_id"}}`, encodeURIComponent(String(stateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update City
         * @param {number} cityId 
         * @param {CityUpdate} cityUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity: async (cityId: number, cityUpdate: CityUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityId' is not null or undefined
            assertParamExists('updateCity', 'cityId', cityId)
            // verify required parameter 'cityUpdate' is not null or undefined
            assertParamExists('updateCity', 'cityUpdate', cityUpdate)
            const localVarPath = `/api/v1/cities/{city_id}`
                .replace(`{${"city_id"}}`, encodeURIComponent(String(cityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cityUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CitiesApi - functional programming interface
 * @export
 */
export const CitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create City
         * @param {CityCreate} cityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCity(cityCreate: CityCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCity(cityCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete City
         * @param {number} cityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCity(cityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCity(cityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cities
         * @param {number} stateId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCities(stateId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCities(stateId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update City
         * @param {number} cityId 
         * @param {CityUpdate} cityUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCity(cityId: number, cityUpdate: CityUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCity(cityId, cityUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CitiesApi - factory interface
 * @export
 */
export const CitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create City
         * @param {CityCreate} cityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCity(cityCreate: CityCreate, options?: any): AxiosPromise<CityList> {
            return localVarFp.createCity(cityCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete City
         * @param {number} cityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCity(cityId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCity(cityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cities
         * @param {number} stateId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities(stateId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<CityList>> {
            return localVarFp.getCities(stateId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update City
         * @param {number} cityId 
         * @param {CityUpdate} cityUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity(cityId: number, cityUpdate: CityUpdate, options?: any): AxiosPromise<CityList> {
            return localVarFp.updateCity(cityId, cityUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCity operation in CitiesApi.
 * @export
 * @interface CitiesApiCreateCityRequest
 */
export interface CitiesApiCreateCityRequest {
    /**
     * 
     * @type {CityCreate}
     * @memberof CitiesApiCreateCity
     */
    readonly cityCreate: CityCreate
}

/**
 * Request parameters for deleteCity operation in CitiesApi.
 * @export
 * @interface CitiesApiDeleteCityRequest
 */
export interface CitiesApiDeleteCityRequest {
    /**
     * 
     * @type {number}
     * @memberof CitiesApiDeleteCity
     */
    readonly cityId: number
}

/**
 * Request parameters for getCities operation in CitiesApi.
 * @export
 * @interface CitiesApiGetCitiesRequest
 */
export interface CitiesApiGetCitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof CitiesApiGetCities
     */
    readonly stateId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof CitiesApiGetCities
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof CitiesApiGetCities
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof CitiesApiGetCities
     */
    readonly filter?: string
}

/**
 * Request parameters for updateCity operation in CitiesApi.
 * @export
 * @interface CitiesApiUpdateCityRequest
 */
export interface CitiesApiUpdateCityRequest {
    /**
     * 
     * @type {number}
     * @memberof CitiesApiUpdateCity
     */
    readonly cityId: number

    /**
     * 
     * @type {CityUpdate}
     * @memberof CitiesApiUpdateCity
     */
    readonly cityUpdate: CityUpdate
}

/**
 * CitiesApi - object-oriented interface
 * @export
 * @class CitiesApi
 * @extends {BaseAPI}
 */
export class CitiesApi extends BaseAPI {
    /**
     * 
     * @summary Create City
     * @param {CitiesApiCreateCityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitiesApi
     */
    public createCity(requestParameters: CitiesApiCreateCityRequest, options?: AxiosRequestConfig) {
        return CitiesApiFp(this.configuration).createCity(requestParameters.cityCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete City
     * @param {CitiesApiDeleteCityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitiesApi
     */
    public deleteCity(requestParameters: CitiesApiDeleteCityRequest, options?: AxiosRequestConfig) {
        return CitiesApiFp(this.configuration).deleteCity(requestParameters.cityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cities
     * @param {CitiesApiGetCitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitiesApi
     */
    public getCities(requestParameters: CitiesApiGetCitiesRequest, options?: AxiosRequestConfig) {
        return CitiesApiFp(this.configuration).getCities(requestParameters.stateId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update City
     * @param {CitiesApiUpdateCityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitiesApi
     */
    public updateCity(requestParameters: CitiesApiUpdateCityRequest, options?: AxiosRequestConfig) {
        return CitiesApiFp(this.configuration).updateCity(requestParameters.cityId, requestParameters.cityUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
