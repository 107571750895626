/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { QuestionnaireOptionsCreate } from '../models';
// @ts-ignore
import { QuestionnaireOptionsList } from '../models';
// @ts-ignore
import { QuestionnaireOptionsUpdate } from '../models';
/**
 * QuestionnaireoptionsApi - axios parameter creator
 * @export
 */
export const QuestionnaireoptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Questionnaireoptions
         * @param {QuestionnaireOptionsCreate} questionnaireOptionsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireoptions: async (questionnaireOptionsCreate: QuestionnaireOptionsCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireOptionsCreate' is not null or undefined
            assertParamExists('createQuestionnaireoptions', 'questionnaireOptionsCreate', questionnaireOptionsCreate)
            const localVarPath = `/api/v1/questionnaireoptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireOptionsCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireoptions: async (questionnaireoptionsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireoptionsId' is not null or undefined
            assertParamExists('deleteQuestionnaireoptions', 'questionnaireoptionsId', questionnaireoptionsId)
            const localVarPath = `/api/v1/questionnaireoptions/{questionnaireoptions_id}`
                .replace(`{${"questionnaireoptions_id"}}`, encodeURIComponent(String(questionnaireoptionsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnaireoption
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireoption: async (questionnaireoptionsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireoptionsId' is not null or undefined
            assertParamExists('getQuestionnaireoption', 'questionnaireoptionsId', questionnaireoptionsId)
            const localVarPath = `/api/v1/questionnaireoptions/{questionnaireoptions_id}`
                .replace(`{${"questionnaireoptions_id"}}`, encodeURIComponent(String(questionnaireoptionsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnaireoptions
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireoptions: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/questionnaireoptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {QuestionnaireOptionsUpdate} questionnaireOptionsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaireoptions: async (questionnaireoptionsId: number, questionnaireOptionsUpdate: QuestionnaireOptionsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireoptionsId' is not null or undefined
            assertParamExists('updateQuestionnaireoptions', 'questionnaireoptionsId', questionnaireoptionsId)
            // verify required parameter 'questionnaireOptionsUpdate' is not null or undefined
            assertParamExists('updateQuestionnaireoptions', 'questionnaireOptionsUpdate', questionnaireOptionsUpdate)
            const localVarPath = `/api/v1/questionnaireoptions/{questionnaireoptions_id}`
                .replace(`{${"questionnaireoptions_id"}}`, encodeURIComponent(String(questionnaireoptionsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireOptionsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnaireoptionsApi - functional programming interface
 * @export
 */
export const QuestionnaireoptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnaireoptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Questionnaireoptions
         * @param {QuestionnaireOptionsCreate} questionnaireOptionsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnaireoptions(questionnaireOptionsCreate: QuestionnaireOptionsCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireOptionsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnaireoptions(questionnaireOptionsCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnaireoptions(questionnaireoptionsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaireoptions(questionnaireoptionsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnaireoption
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaireoption(questionnaireoptionsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireOptionsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireoption(questionnaireoptionsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnaireoptions
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaireoptions(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionnaireOptionsList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireoptions(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {QuestionnaireOptionsUpdate} questionnaireOptionsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionnaireoptions(questionnaireoptionsId: number, questionnaireOptionsUpdate: QuestionnaireOptionsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireOptionsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionnaireoptions(questionnaireoptionsId, questionnaireOptionsUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnaireoptionsApi - factory interface
 * @export
 */
export const QuestionnaireoptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnaireoptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Questionnaireoptions
         * @param {QuestionnaireOptionsCreate} questionnaireOptionsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireoptions(questionnaireOptionsCreate: QuestionnaireOptionsCreate, options?: any): AxiosPromise<QuestionnaireOptionsList> {
            return localVarFp.createQuestionnaireoptions(questionnaireOptionsCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireoptions(questionnaireoptionsId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteQuestionnaireoptions(questionnaireoptionsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnaireoption
         * @param {number} questionnaireoptionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireoption(questionnaireoptionsId: number, options?: any): AxiosPromise<QuestionnaireOptionsList> {
            return localVarFp.getQuestionnaireoption(questionnaireoptionsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnaireoptions
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireoptions(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<QuestionnaireOptionsList>> {
            return localVarFp.getQuestionnaireoptions(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Questionnaireoptions
         * @param {number} questionnaireoptionsId 
         * @param {QuestionnaireOptionsUpdate} questionnaireOptionsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaireoptions(questionnaireoptionsId: number, questionnaireOptionsUpdate: QuestionnaireOptionsUpdate, options?: any): AxiosPromise<QuestionnaireOptionsList> {
            return localVarFp.updateQuestionnaireoptions(questionnaireoptionsId, questionnaireOptionsUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createQuestionnaireoptions operation in QuestionnaireoptionsApi.
 * @export
 * @interface QuestionnaireoptionsApiCreateQuestionnaireoptionsRequest
 */
export interface QuestionnaireoptionsApiCreateQuestionnaireoptionsRequest {
    /**
     * 
     * @type {QuestionnaireOptionsCreate}
     * @memberof QuestionnaireoptionsApiCreateQuestionnaireoptions
     */
    readonly questionnaireOptionsCreate: QuestionnaireOptionsCreate
}

/**
 * Request parameters for deleteQuestionnaireoptions operation in QuestionnaireoptionsApi.
 * @export
 * @interface QuestionnaireoptionsApiDeleteQuestionnaireoptionsRequest
 */
export interface QuestionnaireoptionsApiDeleteQuestionnaireoptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireoptionsApiDeleteQuestionnaireoptions
     */
    readonly questionnaireoptionsId: number
}

/**
 * Request parameters for getQuestionnaireoption operation in QuestionnaireoptionsApi.
 * @export
 * @interface QuestionnaireoptionsApiGetQuestionnaireoptionRequest
 */
export interface QuestionnaireoptionsApiGetQuestionnaireoptionRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireoptionsApiGetQuestionnaireoption
     */
    readonly questionnaireoptionsId: number
}

/**
 * Request parameters for getQuestionnaireoptions operation in QuestionnaireoptionsApi.
 * @export
 * @interface QuestionnaireoptionsApiGetQuestionnaireoptionsRequest
 */
export interface QuestionnaireoptionsApiGetQuestionnaireoptionsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof QuestionnaireoptionsApiGetQuestionnaireoptions
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof QuestionnaireoptionsApiGetQuestionnaireoptions
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof QuestionnaireoptionsApiGetQuestionnaireoptions
     */
    readonly filter?: string
}

/**
 * Request parameters for updateQuestionnaireoptions operation in QuestionnaireoptionsApi.
 * @export
 * @interface QuestionnaireoptionsApiUpdateQuestionnaireoptionsRequest
 */
export interface QuestionnaireoptionsApiUpdateQuestionnaireoptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireoptionsApiUpdateQuestionnaireoptions
     */
    readonly questionnaireoptionsId: number

    /**
     * 
     * @type {QuestionnaireOptionsUpdate}
     * @memberof QuestionnaireoptionsApiUpdateQuestionnaireoptions
     */
    readonly questionnaireOptionsUpdate: QuestionnaireOptionsUpdate
}

/**
 * QuestionnaireoptionsApi - object-oriented interface
 * @export
 * @class QuestionnaireoptionsApi
 * @extends {BaseAPI}
 */
export class QuestionnaireoptionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Questionnaireoptions
     * @param {QuestionnaireoptionsApiCreateQuestionnaireoptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireoptionsApi
     */
    public createQuestionnaireoptions(requestParameters: QuestionnaireoptionsApiCreateQuestionnaireoptionsRequest, options?: AxiosRequestConfig) {
        return QuestionnaireoptionsApiFp(this.configuration).createQuestionnaireoptions(requestParameters.questionnaireOptionsCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Questionnaireoptions
     * @param {QuestionnaireoptionsApiDeleteQuestionnaireoptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireoptionsApi
     */
    public deleteQuestionnaireoptions(requestParameters: QuestionnaireoptionsApiDeleteQuestionnaireoptionsRequest, options?: AxiosRequestConfig) {
        return QuestionnaireoptionsApiFp(this.configuration).deleteQuestionnaireoptions(requestParameters.questionnaireoptionsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnaireoption
     * @param {QuestionnaireoptionsApiGetQuestionnaireoptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireoptionsApi
     */
    public getQuestionnaireoption(requestParameters: QuestionnaireoptionsApiGetQuestionnaireoptionRequest, options?: AxiosRequestConfig) {
        return QuestionnaireoptionsApiFp(this.configuration).getQuestionnaireoption(requestParameters.questionnaireoptionsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnaireoptions
     * @param {QuestionnaireoptionsApiGetQuestionnaireoptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireoptionsApi
     */
    public getQuestionnaireoptions(requestParameters: QuestionnaireoptionsApiGetQuestionnaireoptionsRequest = {}, options?: AxiosRequestConfig) {
        return QuestionnaireoptionsApiFp(this.configuration).getQuestionnaireoptions(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Questionnaireoptions
     * @param {QuestionnaireoptionsApiUpdateQuestionnaireoptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireoptionsApi
     */
    public updateQuestionnaireoptions(requestParameters: QuestionnaireoptionsApiUpdateQuestionnaireoptionsRequest, options?: AxiosRequestConfig) {
        return QuestionnaireoptionsApiFp(this.configuration).updateQuestionnaireoptions(requestParameters.questionnaireoptionsId, requestParameters.questionnaireOptionsUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
