/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryCreate } from '../models';
// @ts-ignore
import { CountryList } from '../models';
// @ts-ignore
import { CountryUpdate } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Country
         * @param {CountryCreate} countryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCountry: async (countryCreate: CountryCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCreate' is not null or undefined
            assertParamExists('createCountry', 'countryCreate', countryCreate)
            const localVarPath = `/api/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry: async (countryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('deleteCountry', 'countryId', countryId)
            const localVarPath = `/api/v1/countries/{country_id}`
                .replace(`{${"country_id"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Countries
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry: async (countryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('getCountry', 'countryId', countryId)
            const localVarPath = `/api/v1/countries/{country_id}`
                .replace(`{${"country_id"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Country
         * @param {number} countryId 
         * @param {CountryUpdate} countryUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry: async (countryId: number, countryUpdate: CountryUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('updateCountry', 'countryId', countryId)
            // verify required parameter 'countryUpdate' is not null or undefined
            assertParamExists('updateCountry', 'countryUpdate', countryUpdate)
            const localVarPath = `/api/v1/countries/{country_id}`
                .replace(`{${"country_id"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Country
         * @param {CountryCreate} countryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCountry(countryCreate: CountryCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCountry(countryCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCountry(countryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCountry(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Countries
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountry(countryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountry(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Country
         * @param {number} countryId 
         * @param {CountryUpdate} countryUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCountry(countryId: number, countryUpdate: CountryUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCountry(countryId, countryUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Country
         * @param {CountryCreate} countryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCountry(countryCreate: CountryCreate, options?: any): AxiosPromise<CountryList> {
            return localVarFp.createCountry(countryCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry(countryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCountry(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Countries
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<CountryList>> {
            return localVarFp.getCountries(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Country
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry(countryId: number, options?: any): AxiosPromise<CountryList> {
            return localVarFp.getCountry(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Country
         * @param {number} countryId 
         * @param {CountryUpdate} countryUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry(countryId: number, countryUpdate: CountryUpdate, options?: any): AxiosPromise<CountryList> {
            return localVarFp.updateCountry(countryId, countryUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCountry operation in CountriesApi.
 * @export
 * @interface CountriesApiCreateCountryRequest
 */
export interface CountriesApiCreateCountryRequest {
    /**
     * 
     * @type {CountryCreate}
     * @memberof CountriesApiCreateCountry
     */
    readonly countryCreate: CountryCreate
}

/**
 * Request parameters for deleteCountry operation in CountriesApi.
 * @export
 * @interface CountriesApiDeleteCountryRequest
 */
export interface CountriesApiDeleteCountryRequest {
    /**
     * 
     * @type {number}
     * @memberof CountriesApiDeleteCountry
     */
    readonly countryId: number
}

/**
 * Request parameters for getCountries operation in CountriesApi.
 * @export
 * @interface CountriesApiGetCountriesRequest
 */
export interface CountriesApiGetCountriesRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof CountriesApiGetCountries
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof CountriesApiGetCountries
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof CountriesApiGetCountries
     */
    readonly filter?: string
}

/**
 * Request parameters for getCountry operation in CountriesApi.
 * @export
 * @interface CountriesApiGetCountryRequest
 */
export interface CountriesApiGetCountryRequest {
    /**
     * 
     * @type {number}
     * @memberof CountriesApiGetCountry
     */
    readonly countryId: number
}

/**
 * Request parameters for updateCountry operation in CountriesApi.
 * @export
 * @interface CountriesApiUpdateCountryRequest
 */
export interface CountriesApiUpdateCountryRequest {
    /**
     * 
     * @type {number}
     * @memberof CountriesApiUpdateCountry
     */
    readonly countryId: number

    /**
     * 
     * @type {CountryUpdate}
     * @memberof CountriesApiUpdateCountry
     */
    readonly countryUpdate: CountryUpdate
}

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @summary Create Country
     * @param {CountriesApiCreateCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public createCountry(requestParameters: CountriesApiCreateCountryRequest, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).createCountry(requestParameters.countryCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Country
     * @param {CountriesApiDeleteCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public deleteCountry(requestParameters: CountriesApiDeleteCountryRequest, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).deleteCountry(requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Countries
     * @param {CountriesApiGetCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public getCountries(requestParameters: CountriesApiGetCountriesRequest = {}, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).getCountries(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Country
     * @param {CountriesApiGetCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public getCountry(requestParameters: CountriesApiGetCountryRequest, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).getCountry(requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Country
     * @param {CountriesApiUpdateCountryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public updateCountry(requestParameters: CountriesApiUpdateCountryRequest, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).updateCountry(requestParameters.countryId, requestParameters.countryUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
