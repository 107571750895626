// project import 
import Applications from './applications'; 
// import DashboardMenu from './dashboard'; 

// types
import { NavItemType } from '../types/menu';
import { JWTContextType } from 'types/auth';


// ==============================|| MENU ITEMS ||============================== //

const menuItemsList = (user: JWTContextType) => {
  const menuItems: { items: NavItemType[] } = {
    items: [...Applications(user)] // widget, chartsMap,
  };
  return menuItems;
}

export default menuItemsList;


