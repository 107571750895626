import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/audiologist')));
// const DashboardAdmin = Loadable(lazy(() => import('pages/dashboard/admin')));  

const AppAdminAnalyticalDashboard  = Loadable(lazy(() => import('pages/dashboard/admin_analytical_dashboard')));
const AppAudiologistAnalyticalDashboard  = Loadable(lazy(() => import('pages/dashboard/audiologist_analytical_dashboard')));

const AppQuestionnaireList = Loadable(lazy(() => import('pages/apps/questions/questionnaire')));
const AppCentreList = Loadable(lazy(() => import('pages/apps/centre/list')));
const AppAudiologistList = Loadable(lazy(() => import('pages/apps/audiologist/list')));
const AppConsultationHistory = Loadable(lazy(() => import('pages/reports/consultation_history')));


// // pages routing
// const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

// const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
// const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
// const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
  
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: '',
              element: <DashboardDefault />
            } 
          ]
        },
        // {
        //   path: 'dashboard',
        //   children: [
        //     {
        //       path: 'admin',
        //       element: <DashboardAdmin />
        //     } 
        //   ]
        // },
        {
          path: 'apps',
          children: [  
            {
              path: 'analytics',
              children: [
                {
                  path: 'admin',
                  element: <AppAdminAnalyticalDashboard />  
                }
              ]
            },
            {
              path: 'analytics',
              children: [
                {
                  path: 'audiologist',
                  element: <AppAudiologistAnalyticalDashboard />  
                }
              ]
            },
            {
              path: 'questions',
              children: [
                {
                  path: 'list',
                  element: <AppQuestionnaireList /> //<AppCentreList />
                }
              ]
            },
            {
              path: 'centre',
              children: [
                {
                  path: 'list',
                  element: <AppCentreList /> //<AppCentreList />
                }
              ]
            },
            {
              path: 'audiologist',
              children: [
                {
                  path: 'list',
                  element: <AppAudiologistList /> 
                } 
              ]
            },
          ]
        }, 
        {
          path: 'reports',
          children: [
            {
              path: 'consultations',
              element: <AppConsultationHistory /> 
            } 
          ]
        },
      ]
    } 
  ]
};

export default MainRoutes;
