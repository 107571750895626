// third-party --------------------------------------
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project imports ----------------------------------
// import axios from 'utils/axios';
// import { dispatch } from '../index';
import { addAsyncThunk } from '../helper';

// Auto Generated openAPI --------------------------------
import { audiologistsApi } from 'providers/env'
import { AudiologistGet as Audiologist, AudiologistList } from 'generated/models'
import { AudiologistsApiGetAudiologistsRequest, AudiologistsApiCreateAudiologistRequest, AudiologistsApiUpdateAudiologistRequest } from '../../generated/api/audiologists-api';
// --------------------------------------------------

type AudiologistState = {
  audiologist: Audiologist | null;
  audiologists: { "list": AudiologistList[], totalRecords: number };
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: AudiologistState = {
  audiologist: null,
  audiologists: { "list": [], totalRecords: 0 },
  status: 'idle',
  error: null
};


// code for async thunks and the slice... 
// Async thunks --------------------------------------
export const getAudiologists = createAsyncThunk('audiologists/getStatus',
  async (requestParameters: AudiologistsApiGetAudiologistsRequest = {}) => {
    const response = await audiologistsApi.getAudiologists(requestParameters);
    return { "list": response.data, totalRecords: response.headers["content-range"].split('/')[1] };
  });

export const createAudiologist = createAsyncThunk('audiologists/createStatus',
  async (itemData: AudiologistsApiCreateAudiologistRequest) => {
    const response = await audiologistsApi.createAudiologist(itemData)
    return response.data
  }
);

export const deleteAudiologist = createAsyncThunk('audiologists/deleteStatus',
  async (audiologistId: number) => {
    const response = await audiologistsApi.deleteAudiologist({ audiologistId });
    return response.data;
  }
);

export const getAudiologist = createAsyncThunk('audiologists/getSingleStatus',
  async (audiologistId: number) => {
    const response = await audiologistsApi.getAudiologist({ audiologistId });
    return response.data;
  }
);

export const updateAudiologist = createAsyncThunk('audiologists/updateStatus',
  async (updateData: AudiologistsApiUpdateAudiologistRequest) => {
    const response = await audiologistsApi.updateAudiologist(updateData);
    return response.data;
  }
);

const audiologistSlice = createSlice({
  name: 'audiologist',
  initialState,
  reducers: {
    // Any synchronous actions would go here
  },
  extraReducers: (builder) => {
    // the helper function to add the cases for each async thunk
    addAsyncThunk(builder, getAudiologists, 'audiologists');
    addAsyncThunk(builder, createAudiologist, 'audiologist');
    addAsyncThunk(builder, deleteAudiologist, 'deleteStatus');
    addAsyncThunk(builder, getAudiologist, 'getSingleStatus');
    addAsyncThunk(builder, updateAudiologist, 'updateStatus');
  },
});

export default audiologistSlice.reducer;
