/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './api/analytics-api';
export * from './api/audiologists-api';
export * from './api/auth-api';
export * from './api/centres-api';
export * from './api/cities-api';
export * from './api/consultationrequests-api';
export * from './api/countries-api';
export * from './api/language-api';
export * from './api/patients-api';
export * from './api/questionnairegroups-api';
export * from './api/questionnaireoptions-api';
export * from './api/questionnaires-api';
export * from './api/states-api';
export * from './api/testdocuments-api';
export * from './api/testquestionnaires-api';
export * from './api/testresults-api';
export * from './api/tests-api';
export * from './api/users-api';

