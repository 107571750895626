const users = [
  {
    id: '5e86809283e28b96d2d38537',
    // email: 'admin@labataisa.com',
    // password: '123456',
    email: '',
    password: '',
    name: 'JWT User'
  }
];

export default users;
