import { AsyncThunk, AnyAction } from '@reduxjs/toolkit';

// Helper function to reduce boilerplate
export function addAsyncThunk(builder: any, asyncThunk: AsyncThunk<any, any, {}>, name: string) {
    builder
      .addCase(asyncThunk.pending, (state) => {
        // state.loading = `${name} loading`;
        state.loading = `loading`;
      })
      .addCase(asyncThunk.fulfilled, (state, action: AnyAction) => {
        state.loading = 'idle';
        state[name] = action.payload;
      })
      .addCase(asyncThunk.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  }