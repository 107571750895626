// material-ui
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/earkart-logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="earKARTomni" width="100" />
     *
     */
    <img src={logo} alt="earKart" style={{ height: '60px', marginTop: '5px' }} /> 
  );
};

export default LogoMain;
