/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AudiometryDevicesList } from '../models';
// @ts-ignore
import { CentreCreate } from '../models';
// @ts-ignore
import { CentreGet } from '../models';
// @ts-ignore
import { CentreList } from '../models';
// @ts-ignore
import { CentreUpdate } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * CentresApi - axios parameter creator
 * @export
 */
export const CentresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Centre
         * @param {CentreCreate} centreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentre: async (centreCreate: CentreCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreCreate' is not null or undefined
            assertParamExists('createCentre', 'centreCreate', centreCreate)
            const localVarPath = `/api/v1/centres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(centreCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCentre: async (centreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('deleteCentre', 'centreId', centreId)
            const localVarPath = `/api/v1/centres/{centre_id}`
                .replace(`{${"centre_id"}}`, encodeURIComponent(String(centreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentre: async (centreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('getCentre', 'centreId', centreId)
            const localVarPath = `/api/v1/centres/{centre_id}`
                .replace(`{${"centre_id"}}`, encodeURIComponent(String(centreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Centre Info
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreInfo: async (centreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('getCentreInfo', 'centreId', centreId)
            const localVarPath = `/api/v1/centres/info/{centre_id}`
                .replace(`{${"centre_id"}}`, encodeURIComponent(String(centreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentres: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/centres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Devices For Center
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForCenter: async (centreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('getDevicesForCenter', 'centreId', centreId)
            const localVarPath = `/api/v1/centres/{centre_id}/devices`
                .replace(`{${"centre_id"}}`, encodeURIComponent(String(centreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Centre
         * @param {number} centreId 
         * @param {CentreUpdate} centreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentre: async (centreId: number, centreUpdate: CentreUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('updateCentre', 'centreId', centreId)
            // verify required parameter 'centreUpdate' is not null or undefined
            assertParamExists('updateCentre', 'centreUpdate', centreUpdate)
            const localVarPath = `/api/v1/centres/{centre_id}`
                .replace(`{${"centre_id"}}`, encodeURIComponent(String(centreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(centreUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CentresApi - functional programming interface
 * @export
 */
export const CentresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CentresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Centre
         * @param {CentreCreate} centreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCentre(centreCreate: CentreCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CentreGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCentre(centreCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCentre(centreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCentre(centreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCentre(centreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CentreGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCentre(centreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Centre Info
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCentreInfo(centreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CentreList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCentreInfo(centreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCentres(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CentreList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCentres(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Devices For Center
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesForCenter(centreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudiometryDevicesList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesForCenter(centreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Centre
         * @param {number} centreId 
         * @param {CentreUpdate} centreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCentre(centreId: number, centreUpdate: CentreUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CentreGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCentre(centreId, centreUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CentresApi - factory interface
 * @export
 */
export const CentresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CentresApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Centre
         * @param {CentreCreate} centreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentre(centreCreate: CentreCreate, options?: any): AxiosPromise<CentreGet> {
            return localVarFp.createCentre(centreCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCentre(centreId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCentre(centreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Centre
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentre(centreId: number, options?: any): AxiosPromise<CentreGet> {
            return localVarFp.getCentre(centreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Centre Info
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreInfo(centreId: number, options?: any): AxiosPromise<CentreList> {
            return localVarFp.getCentreInfo(centreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentres(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<CentreList>> {
            return localVarFp.getCentres(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Devices For Center
         * @param {number} centreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForCenter(centreId: number, options?: any): AxiosPromise<Array<AudiometryDevicesList>> {
            return localVarFp.getDevicesForCenter(centreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Centre
         * @param {number} centreId 
         * @param {CentreUpdate} centreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentre(centreId: number, centreUpdate: CentreUpdate, options?: any): AxiosPromise<CentreGet> {
            return localVarFp.updateCentre(centreId, centreUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCentre operation in CentresApi.
 * @export
 * @interface CentresApiCreateCentreRequest
 */
export interface CentresApiCreateCentreRequest {
    /**
     * 
     * @type {CentreCreate}
     * @memberof CentresApiCreateCentre
     */
    readonly centreCreate: CentreCreate
}

/**
 * Request parameters for deleteCentre operation in CentresApi.
 * @export
 * @interface CentresApiDeleteCentreRequest
 */
export interface CentresApiDeleteCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof CentresApiDeleteCentre
     */
    readonly centreId: number
}

/**
 * Request parameters for getCentre operation in CentresApi.
 * @export
 * @interface CentresApiGetCentreRequest
 */
export interface CentresApiGetCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof CentresApiGetCentre
     */
    readonly centreId: number
}

/**
 * Request parameters for getCentreInfo operation in CentresApi.
 * @export
 * @interface CentresApiGetCentreInfoRequest
 */
export interface CentresApiGetCentreInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof CentresApiGetCentreInfo
     */
    readonly centreId: number
}

/**
 * Request parameters for getCentres operation in CentresApi.
 * @export
 * @interface CentresApiGetCentresRequest
 */
export interface CentresApiGetCentresRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof CentresApiGetCentres
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof CentresApiGetCentres
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof CentresApiGetCentres
     */
    readonly filter?: string
}

/**
 * Request parameters for getDevicesForCenter operation in CentresApi.
 * @export
 * @interface CentresApiGetDevicesForCenterRequest
 */
export interface CentresApiGetDevicesForCenterRequest {
    /**
     * 
     * @type {number}
     * @memberof CentresApiGetDevicesForCenter
     */
    readonly centreId: number
}

/**
 * Request parameters for updateCentre operation in CentresApi.
 * @export
 * @interface CentresApiUpdateCentreRequest
 */
export interface CentresApiUpdateCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof CentresApiUpdateCentre
     */
    readonly centreId: number

    /**
     * 
     * @type {CentreUpdate}
     * @memberof CentresApiUpdateCentre
     */
    readonly centreUpdate: CentreUpdate
}

/**
 * CentresApi - object-oriented interface
 * @export
 * @class CentresApi
 * @extends {BaseAPI}
 */
export class CentresApi extends BaseAPI {
    /**
     * 
     * @summary Create Centre
     * @param {CentresApiCreateCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public createCentre(requestParameters: CentresApiCreateCentreRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).createCentre(requestParameters.centreCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Centre
     * @param {CentresApiDeleteCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public deleteCentre(requestParameters: CentresApiDeleteCentreRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).deleteCentre(requestParameters.centreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Centre
     * @param {CentresApiGetCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public getCentre(requestParameters: CentresApiGetCentreRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).getCentre(requestParameters.centreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Centre Info
     * @param {CentresApiGetCentreInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public getCentreInfo(requestParameters: CentresApiGetCentreInfoRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).getCentreInfo(requestParameters.centreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Centres
     * @param {CentresApiGetCentresRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public getCentres(requestParameters: CentresApiGetCentresRequest = {}, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).getCentres(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Devices For Center
     * @param {CentresApiGetDevicesForCenterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public getDevicesForCenter(requestParameters: CentresApiGetDevicesForCenterRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).getDevicesForCenter(requestParameters.centreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Centre
     * @param {CentresApiUpdateCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentresApi
     */
    public updateCentre(requestParameters: CentresApiUpdateCentreRequest, options?: AxiosRequestConfig) {
        return CentresApiFp(this.configuration).updateCentre(requestParameters.centreId, requestParameters.centreUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
