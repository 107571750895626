/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { TestResultsCreate } from '../models';
// @ts-ignore
import { TestResultsList } from '../models';
// @ts-ignore
import { TestResultsUpdate } from '../models';
/**
 * TestresultsApi - axios parameter creator
 * @export
 */
export const TestresultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Testresults
         * @param {TestResultsCreate} testResultsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestresults: async (testResultsCreate: TestResultsCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testResultsCreate' is not null or undefined
            assertParamExists('createTestresults', 'testResultsCreate', testResultsCreate)
            const localVarPath = `/api/v1/testresults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testResultsCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Testresults
         * @param {number} testId 
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestresults: async (testId: number, testresultsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('deleteTestresults', 'testId', testId)
            // verify required parameter 'testresultsId' is not null or undefined
            assertParamExists('deleteTestresults', 'testresultsId', testresultsId)
            const localVarPath = `/api/v1/testresults/{test_id}/{testresults_id}`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)))
                .replace(`{${"testresults_id"}}`, encodeURIComponent(String(testresultsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testresult
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestresult: async (testresultsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testresultsId' is not null or undefined
            assertParamExists('getTestresult', 'testresultsId', testresultsId)
            const localVarPath = `/api/v1/testresults/{testresults_id}`
                .replace(`{${"testresults_id"}}`, encodeURIComponent(String(testresultsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testresults
         * @param {number} testId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestresults: async (testId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getTestresults', 'testId', testId)
            const localVarPath = `/api/v1/testresults/all/{test_id}`
                .replace(`{${"test_id"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testresults
         * @param {number} testresultsId 
         * @param {TestResultsUpdate} testResultsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestresults: async (testresultsId: number, testResultsUpdate: TestResultsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testresultsId' is not null or undefined
            assertParamExists('updateTestresults', 'testresultsId', testresultsId)
            // verify required parameter 'testResultsUpdate' is not null or undefined
            assertParamExists('updateTestresults', 'testResultsUpdate', testResultsUpdate)
            const localVarPath = `/api/v1/testresults/{testresults_id}`
                .replace(`{${"testresults_id"}}`, encodeURIComponent(String(testresultsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testResultsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestresultsApi - functional programming interface
 * @export
 */
export const TestresultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestresultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Testresults
         * @param {TestResultsCreate} testResultsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestresults(testResultsCreate: TestResultsCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResultsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestresults(testResultsCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Testresults
         * @param {number} testId 
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestresults(testId: number, testresultsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestresults(testId, testresultsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testresult
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestresult(testresultsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResultsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestresult(testresultsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testresults
         * @param {number} testId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestresults(testId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestResultsList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestresults(testId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Testresults
         * @param {number} testresultsId 
         * @param {TestResultsUpdate} testResultsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestresults(testresultsId: number, testResultsUpdate: TestResultsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResultsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestresults(testresultsId, testResultsUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestresultsApi - factory interface
 * @export
 */
export const TestresultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestresultsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Testresults
         * @param {TestResultsCreate} testResultsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestresults(testResultsCreate: TestResultsCreate, options?: any): AxiosPromise<TestResultsList> {
            return localVarFp.createTestresults(testResultsCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Testresults
         * @param {number} testId 
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestresults(testId: number, testresultsId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTestresults(testId, testresultsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testresult
         * @param {number} testresultsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestresult(testresultsId: number, options?: any): AxiosPromise<TestResultsList> {
            return localVarFp.getTestresult(testresultsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testresults
         * @param {number} testId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestresults(testId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<TestResultsList>> {
            return localVarFp.getTestresults(testId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testresults
         * @param {number} testresultsId 
         * @param {TestResultsUpdate} testResultsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestresults(testresultsId: number, testResultsUpdate: TestResultsUpdate, options?: any): AxiosPromise<TestResultsList> {
            return localVarFp.updateTestresults(testresultsId, testResultsUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTestresults operation in TestresultsApi.
 * @export
 * @interface TestresultsApiCreateTestresultsRequest
 */
export interface TestresultsApiCreateTestresultsRequest {
    /**
     * 
     * @type {TestResultsCreate}
     * @memberof TestresultsApiCreateTestresults
     */
    readonly testResultsCreate: TestResultsCreate
}

/**
 * Request parameters for deleteTestresults operation in TestresultsApi.
 * @export
 * @interface TestresultsApiDeleteTestresultsRequest
 */
export interface TestresultsApiDeleteTestresultsRequest {
    /**
     * 
     * @type {number}
     * @memberof TestresultsApiDeleteTestresults
     */
    readonly testId: number

    /**
     * 
     * @type {number}
     * @memberof TestresultsApiDeleteTestresults
     */
    readonly testresultsId: number
}

/**
 * Request parameters for getTestresult operation in TestresultsApi.
 * @export
 * @interface TestresultsApiGetTestresultRequest
 */
export interface TestresultsApiGetTestresultRequest {
    /**
     * 
     * @type {number}
     * @memberof TestresultsApiGetTestresult
     */
    readonly testresultsId: number
}

/**
 * Request parameters for getTestresults operation in TestresultsApi.
 * @export
 * @interface TestresultsApiGetTestresultsRequest
 */
export interface TestresultsApiGetTestresultsRequest {
    /**
     * 
     * @type {number}
     * @memberof TestresultsApiGetTestresults
     */
    readonly testId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof TestresultsApiGetTestresults
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof TestresultsApiGetTestresults
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof TestresultsApiGetTestresults
     */
    readonly filter?: string
}

/**
 * Request parameters for updateTestresults operation in TestresultsApi.
 * @export
 * @interface TestresultsApiUpdateTestresultsRequest
 */
export interface TestresultsApiUpdateTestresultsRequest {
    /**
     * 
     * @type {number}
     * @memberof TestresultsApiUpdateTestresults
     */
    readonly testresultsId: number

    /**
     * 
     * @type {TestResultsUpdate}
     * @memberof TestresultsApiUpdateTestresults
     */
    readonly testResultsUpdate: TestResultsUpdate
}

/**
 * TestresultsApi - object-oriented interface
 * @export
 * @class TestresultsApi
 * @extends {BaseAPI}
 */
export class TestresultsApi extends BaseAPI {
    /**
     * 
     * @summary Create Testresults
     * @param {TestresultsApiCreateTestresultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestresultsApi
     */
    public createTestresults(requestParameters: TestresultsApiCreateTestresultsRequest, options?: AxiosRequestConfig) {
        return TestresultsApiFp(this.configuration).createTestresults(requestParameters.testResultsCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Testresults
     * @param {TestresultsApiDeleteTestresultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestresultsApi
     */
    public deleteTestresults(requestParameters: TestresultsApiDeleteTestresultsRequest, options?: AxiosRequestConfig) {
        return TestresultsApiFp(this.configuration).deleteTestresults(requestParameters.testId, requestParameters.testresultsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testresult
     * @param {TestresultsApiGetTestresultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestresultsApi
     */
    public getTestresult(requestParameters: TestresultsApiGetTestresultRequest, options?: AxiosRequestConfig) {
        return TestresultsApiFp(this.configuration).getTestresult(requestParameters.testresultsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testresults
     * @param {TestresultsApiGetTestresultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestresultsApi
     */
    public getTestresults(requestParameters: TestresultsApiGetTestresultsRequest, options?: AxiosRequestConfig) {
        return TestresultsApiFp(this.configuration).getTestresults(requestParameters.testId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testresults
     * @param {TestresultsApiUpdateTestresultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestresultsApi
     */
    public updateTestresults(requestParameters: TestresultsApiUpdateTestresultsRequest, options?: AxiosRequestConfig) {
        return TestresultsApiFp(this.configuration).updateTestresults(requestParameters.testresultsId, requestParameters.testResultsUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
