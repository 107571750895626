import { lazy } from 'react';

// project import
import ConsultationLayout from 'layout/ConsultationLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const Consultation = Loadable(lazy(() => import('pages/consultation')));
const PatientTestReport = Loadable(lazy(() => import('pages/reports/patient_test_report')));
const PatientConsultation = Loadable(lazy(() => import('pages/patientVcPanel')));


// ==============================|| MAIN ROUTING ||============================== //

const ConsultationRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <ConsultationLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'consultation',
                    children: [
                        {
                            path: '',
                            element: <Consultation />
                        }
                    ]
                }
            ]
        },
        {
            path: '/patient',  
            children: [
                {
                    path: 'consultation/:roomName/:participantName',
                    children: [
                        {
                            path: '',
                            element: <PatientConsultation />
                        }
                    ]
                }
            ]
        },
        {
            path: '/consultation/test',  
            children: [
                {
                    path: 'report/:id/:authkey/:print',
                    children: [
                        {
                            path: '',
                            element: <PatientTestReport />
                        }
                    ]
                }
            ]
        }
    ]
};

export default ConsultationRoutes;
