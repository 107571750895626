/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { TestQuestionnaireCreate } from '../models';
// @ts-ignore
import { TestQuestionnaireList } from '../models';
// @ts-ignore
import { TestQuestionnaireListCreateSchema } from '../models';
// @ts-ignore
import { TestQuestionnaireUpdate } from '../models';
/**
 * TestquestionnairesApi - axios parameter creator
 * @export
 */
export const TestquestionnairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Testquestionnaire
         * @param {TestQuestionnaireCreate} testQuestionnaireCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestquestionnaire: async (testQuestionnaireCreate: TestQuestionnaireCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testQuestionnaireCreate' is not null or undefined
            assertParamExists('createTestquestionnaire', 'testQuestionnaireCreate', testQuestionnaireCreate)
            const localVarPath = `/api/v1/testquestionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testQuestionnaireCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testquestionnaire Bulk
         * @param {number} consultationId 
         * @param {TestQuestionnaireListCreateSchema} testQuestionnaireListCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestquestionnaireBulk: async (consultationId: number, testQuestionnaireListCreateSchema: TestQuestionnaireListCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('createTestquestionnaireBulk', 'consultationId', consultationId)
            // verify required parameter 'testQuestionnaireListCreateSchema' is not null or undefined
            assertParamExists('createTestquestionnaireBulk', 'testQuestionnaireListCreateSchema', testQuestionnaireListCreateSchema)
            const localVarPath = `/api/v1/testquestionnaires/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (consultationId !== undefined) {
                localVarQueryParameter['consultation_id'] = consultationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testQuestionnaireListCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestquestionnaire: async (testquestionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testquestionnaireId' is not null or undefined
            assertParamExists('deleteTestquestionnaire', 'testquestionnaireId', testquestionnaireId)
            const localVarPath = `/api/v1/testquestionnaires/{testquestionnaire_id}`
                .replace(`{${"testquestionnaire_id"}}`, encodeURIComponent(String(testquestionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestquestionnaire: async (testquestionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testquestionnaireId' is not null or undefined
            assertParamExists('getTestquestionnaire', 'testquestionnaireId', testquestionnaireId)
            const localVarPath = `/api/v1/testquestionnaires/{testquestionnaire_id}`
                .replace(`{${"testquestionnaire_id"}}`, encodeURIComponent(String(testquestionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testquestionnaires
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestquestionnaires: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/testquestionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {TestQuestionnaireUpdate} testQuestionnaireUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestquestionnaire: async (testquestionnaireId: number, testQuestionnaireUpdate: TestQuestionnaireUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testquestionnaireId' is not null or undefined
            assertParamExists('updateTestquestionnaire', 'testquestionnaireId', testquestionnaireId)
            // verify required parameter 'testQuestionnaireUpdate' is not null or undefined
            assertParamExists('updateTestquestionnaire', 'testQuestionnaireUpdate', testQuestionnaireUpdate)
            const localVarPath = `/api/v1/testquestionnaires/{testquestionnaire_id}`
                .replace(`{${"testquestionnaire_id"}}`, encodeURIComponent(String(testquestionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testQuestionnaireUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestquestionnairesApi - functional programming interface
 * @export
 */
export const TestquestionnairesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestquestionnairesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Testquestionnaire
         * @param {TestQuestionnaireCreate} testQuestionnaireCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestquestionnaire(testQuestionnaireCreate: TestQuestionnaireCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestQuestionnaireList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestquestionnaire(testQuestionnaireCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Testquestionnaire Bulk
         * @param {number} consultationId 
         * @param {TestQuestionnaireListCreateSchema} testQuestionnaireListCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestquestionnaireBulk(consultationId: number, testQuestionnaireListCreateSchema: TestQuestionnaireListCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestquestionnaireBulk(consultationId, testQuestionnaireListCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestquestionnaire(testquestionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestquestionnaire(testquestionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestquestionnaire(testquestionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestQuestionnaireList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestquestionnaire(testquestionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Testquestionnaires
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestquestionnaires(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestQuestionnaireList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestquestionnaires(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {TestQuestionnaireUpdate} testQuestionnaireUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestquestionnaire(testquestionnaireId: number, testQuestionnaireUpdate: TestQuestionnaireUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestQuestionnaireList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestquestionnaire(testquestionnaireId, testQuestionnaireUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestquestionnairesApi - factory interface
 * @export
 */
export const TestquestionnairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestquestionnairesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Testquestionnaire
         * @param {TestQuestionnaireCreate} testQuestionnaireCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestquestionnaire(testQuestionnaireCreate: TestQuestionnaireCreate, options?: any): AxiosPromise<TestQuestionnaireList> {
            return localVarFp.createTestquestionnaire(testQuestionnaireCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testquestionnaire Bulk
         * @param {number} consultationId 
         * @param {TestQuestionnaireListCreateSchema} testQuestionnaireListCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestquestionnaireBulk(consultationId: number, testQuestionnaireListCreateSchema: TestQuestionnaireListCreateSchema, options?: any): AxiosPromise<any> {
            return localVarFp.createTestquestionnaireBulk(consultationId, testQuestionnaireListCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestquestionnaire(testquestionnaireId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTestquestionnaire(testquestionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestquestionnaire(testquestionnaireId: number, options?: any): AxiosPromise<TestQuestionnaireList> {
            return localVarFp.getTestquestionnaire(testquestionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testquestionnaires
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestquestionnaires(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<TestQuestionnaireList>> {
            return localVarFp.getTestquestionnaires(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testquestionnaire
         * @param {number} testquestionnaireId 
         * @param {TestQuestionnaireUpdate} testQuestionnaireUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestquestionnaire(testquestionnaireId: number, testQuestionnaireUpdate: TestQuestionnaireUpdate, options?: any): AxiosPromise<TestQuestionnaireList> {
            return localVarFp.updateTestquestionnaire(testquestionnaireId, testQuestionnaireUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTestquestionnaire operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiCreateTestquestionnaireRequest
 */
export interface TestquestionnairesApiCreateTestquestionnaireRequest {
    /**
     * 
     * @type {TestQuestionnaireCreate}
     * @memberof TestquestionnairesApiCreateTestquestionnaire
     */
    readonly testQuestionnaireCreate: TestQuestionnaireCreate
}

/**
 * Request parameters for createTestquestionnaireBulk operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiCreateTestquestionnaireBulkRequest
 */
export interface TestquestionnairesApiCreateTestquestionnaireBulkRequest {
    /**
     * 
     * @type {number}
     * @memberof TestquestionnairesApiCreateTestquestionnaireBulk
     */
    readonly consultationId: number

    /**
     * 
     * @type {TestQuestionnaireListCreateSchema}
     * @memberof TestquestionnairesApiCreateTestquestionnaireBulk
     */
    readonly testQuestionnaireListCreateSchema: TestQuestionnaireListCreateSchema
}

/**
 * Request parameters for deleteTestquestionnaire operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiDeleteTestquestionnaireRequest
 */
export interface TestquestionnairesApiDeleteTestquestionnaireRequest {
    /**
     * 
     * @type {number}
     * @memberof TestquestionnairesApiDeleteTestquestionnaire
     */
    readonly testquestionnaireId: number
}

/**
 * Request parameters for getTestquestionnaire operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiGetTestquestionnaireRequest
 */
export interface TestquestionnairesApiGetTestquestionnaireRequest {
    /**
     * 
     * @type {number}
     * @memberof TestquestionnairesApiGetTestquestionnaire
     */
    readonly testquestionnaireId: number
}

/**
 * Request parameters for getTestquestionnaires operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiGetTestquestionnairesRequest
 */
export interface TestquestionnairesApiGetTestquestionnairesRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof TestquestionnairesApiGetTestquestionnaires
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof TestquestionnairesApiGetTestquestionnaires
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof TestquestionnairesApiGetTestquestionnaires
     */
    readonly filter?: string
}

/**
 * Request parameters for updateTestquestionnaire operation in TestquestionnairesApi.
 * @export
 * @interface TestquestionnairesApiUpdateTestquestionnaireRequest
 */
export interface TestquestionnairesApiUpdateTestquestionnaireRequest {
    /**
     * 
     * @type {number}
     * @memberof TestquestionnairesApiUpdateTestquestionnaire
     */
    readonly testquestionnaireId: number

    /**
     * 
     * @type {TestQuestionnaireUpdate}
     * @memberof TestquestionnairesApiUpdateTestquestionnaire
     */
    readonly testQuestionnaireUpdate: TestQuestionnaireUpdate
}

/**
 * TestquestionnairesApi - object-oriented interface
 * @export
 * @class TestquestionnairesApi
 * @extends {BaseAPI}
 */
export class TestquestionnairesApi extends BaseAPI {
    /**
     * 
     * @summary Create Testquestionnaire
     * @param {TestquestionnairesApiCreateTestquestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public createTestquestionnaire(requestParameters: TestquestionnairesApiCreateTestquestionnaireRequest, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).createTestquestionnaire(requestParameters.testQuestionnaireCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testquestionnaire Bulk
     * @param {TestquestionnairesApiCreateTestquestionnaireBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public createTestquestionnaireBulk(requestParameters: TestquestionnairesApiCreateTestquestionnaireBulkRequest, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).createTestquestionnaireBulk(requestParameters.consultationId, requestParameters.testQuestionnaireListCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Testquestionnaire
     * @param {TestquestionnairesApiDeleteTestquestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public deleteTestquestionnaire(requestParameters: TestquestionnairesApiDeleteTestquestionnaireRequest, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).deleteTestquestionnaire(requestParameters.testquestionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testquestionnaire
     * @param {TestquestionnairesApiGetTestquestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public getTestquestionnaire(requestParameters: TestquestionnairesApiGetTestquestionnaireRequest, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).getTestquestionnaire(requestParameters.testquestionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testquestionnaires
     * @param {TestquestionnairesApiGetTestquestionnairesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public getTestquestionnaires(requestParameters: TestquestionnairesApiGetTestquestionnairesRequest = {}, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).getTestquestionnaires(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testquestionnaire
     * @param {TestquestionnairesApiUpdateTestquestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestquestionnairesApi
     */
    public updateTestquestionnaire(requestParameters: TestquestionnairesApiUpdateTestquestionnaireRequest, options?: AxiosRequestConfig) {
        return TestquestionnairesApiFp(this.configuration).updateTestquestionnaire(requestParameters.testquestionnaireId, requestParameters.testQuestionnaireUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
