import { FC } from 'react';
import { useSnackbar, SnackbarProvider, OptionsObject, SnackbarKey } from 'notistack';

interface IProps {
  setUseSnackbarRef: (enqueueSnackbar: (message: string, options?: OptionsObject) => SnackbarKey) => void;
}

const InnerSnackbarUtilsConfigurator: FC<IProps> = (props: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  props.setUseSnackbarRef(enqueueSnackbar);
  return null;
};

let enqueueSnackbarRef: (message: string, options?: OptionsObject) => SnackbarKey;
const setUseSnackbarRef = (enqueueSnackbarRefProp: (message: string, options?: OptionsObject) => SnackbarKey) => {
  enqueueSnackbarRef = enqueueSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <SnackbarProvider>
      <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    </SnackbarProvider>
  );
};

export default function toast(msg: string, options: OptionsObject = {}) {
  enqueueSnackbarRef(msg, options);
}
