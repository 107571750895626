// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import menu from './menu';
import snackbar from './snackbar';
import centreReducer from './centre';
import audiologistReducer from './audiologist';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,  
  centre: centreReducer,
  audiologist: audiologistReducer
});

export default reducers;
