import { FC, Suspense } from 'react';
import Loader from './Loader';

const Loadable = (Component: FC<any>) => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;


// import { ElementType, Suspense } from 'react';

// // project import
// import Loader from './Loader';

// // ==============================|| LOADABLE - LAZY LOADING ||============================== //

// const Loadable = (Component: ElementType) => (props: any) =>
//   (
//     <Suspense fallback={<Loader />}>
//       <Component {...props} />
//     </Suspense>
//   );

// export default Loadable;
