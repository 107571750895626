// third-party --------------------------------------
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project imports ----------------------------------
// import axios from 'utils/axios';
// import { dispatch } from '../index';
import { addAsyncThunk } from '../helper';

// Auto Generated openAPI --------------------------------
import { centresApi } from 'providers/env'
import { CentreGet as Centre, CentreList } from 'generated/models'
import { CentresApiGetCentresRequest, CentresApiCreateCentreRequest, CentresApiUpdateCentreRequest } from '../../generated/api/centres-api';
// --------------------------------------------------

type CentreState = {
  centre: Centre | null;
  centres: { "list": CentreList[], totalRecords: number };
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: CentreState = {
  centre: null,
  centres: { "list": [], totalRecords: 0 },
  status: 'idle',
  error: null
};


// code for async thunks and the slice... 
// Async thunks --------------------------------------
export const getCentres = createAsyncThunk('centres/getStatus',
  async (requestParameters: CentresApiGetCentresRequest = {}) => {
    const response = await centresApi.getCentres(requestParameters);
    return { "list": response.data, totalRecords: response.headers["content-range"].split('/')[1] };
  });

export const createCentre = createAsyncThunk('centres/createStatus',
  async (itemData: CentresApiCreateCentreRequest) => {
    const response = await centresApi.createCentre(itemData)
    return response.data
  }
);

export const deleteCentre = createAsyncThunk('centres/deleteStatus',
  async (centreId: number) => {
    const response = await centresApi.deleteCentre({ centreId });
    return response.data;
  }
);

export const getCentre = createAsyncThunk('centres/getSingleStatus',
  async (centreId: number) => {
    const response = await centresApi.getCentre({ centreId });
    return response.data;
  }
);

export const updateCentre = createAsyncThunk('centres/updateStatus',
  async (updateData: CentresApiUpdateCentreRequest) => {
    const response = await centresApi.updateCentre(updateData);
    return response.data;
  }
);

export const getCentreInfo = createAsyncThunk('centres/getSingleStatus',
  async (centreId: number) => {
    const response = await centresApi.getCentreInfo({ centreId });
    return response.data;
  }
);

export const getDevices = createAsyncThunk('devices/getStatus',
  async (centreId: number) => {
    const response = await centresApi.getDevicesForCenter({ centreId });
    return response.data;
  });


const centreSlice = createSlice({
  name: 'centre',
  initialState,
  reducers: {
    // Any synchronous actions would go here
  },
  extraReducers: (builder) => {
    // the helper function to add the cases for each async thunk
    addAsyncThunk(builder, getCentres, 'centres');
    addAsyncThunk(builder, createCentre, 'centre');
    addAsyncThunk(builder, deleteCentre, 'deleteStatus');
    addAsyncThunk(builder, getCentre, 'getSingleStatus');
    addAsyncThunk(builder, updateCentre, 'updateStatus');
  },
});

export default centreSlice.reducer;
